import DescriptionIcon from '@material-ui/icons/Description';
import ReclamationList from './ReclamationList';
import ReclamationEdit from './ReclamationEdit';
import { RegisterResource } from '@components/ResourceGuesser';
import { Reclamation } from '@js/interfaces/reclamation';

export default {
    icon: DescriptionIcon,
    list: ReclamationList,
    show: null,
    create: null,
    edit: ReclamationEdit,
    options: {
        alwaysHideFields: [
            'errand',
            'overrideResellers',
            'selloSynced',
            'split',
            'splitQuantityLeft',
            'parent',
            'child',
            'selloProductId',
            'statusNotifications',
            'copiedFieldsFromSavingsCalculation',
            'averageSaleTime',
            'selloExitingProductSync',
            'printLabelsQuantitySameAsQuantity',
        ],
    },
} satisfies RegisterResource<Reclamation>;
