import { ReactNode } from 'react';
import { useTranslate } from 'react-admin';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, LinearProgress } from '@material-ui/core';
import CancelIcon from '@material-ui/icons/Cancel';

import useIsSmallScreen from '@js/hooks/useIsSmallScreen';

interface Props {
    title: string;
    open: boolean;
    children: ReactNode;
    loading?: boolean;
    onClose: () => void;
    onExited?: () => void;
}

const DialogCodeScanner = ({ open, title, onClose, loading, children, onExited }: Props) => {
    const isFullScreen = useIsSmallScreen();
    const translate = useTranslate();

    return (
        <Dialog open={open} onClose={onClose} TransitionProps={{ onExited }} fullScreen={isFullScreen}>
            <DialogTitle>{title}</DialogTitle>
            <DialogContent>
                {loading && <LinearProgress />}
                {children}
            </DialogContent>
            <DialogActions>
                <Button startIcon={<CancelIcon />} color="default" onClick={onClose}>
                    {translate('ra.action.cancel')}
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default DialogCodeScanner;
