import AccessTimeIcon from '@material-ui/icons/AccessTime';

import ProcessingTimeDefaultsList from './ProcessingTimeDefaultsList';
import ProcessingTimeDefaultsForm from './ProcessingTimeDefaultsForm';

import { RegisterResource } from '@components/ResourceGuesser';
import { ProcessingTimeDefaults } from '@js/interfaces/ProcessingTimeDefaults';

export default {
    list: ProcessingTimeDefaultsList,
    show: null,
    icon: AccessTimeIcon,
    options: {
        form: ProcessingTimeDefaultsForm,
    },
} satisfies RegisterResource<ProcessingTimeDefaults>;
