import { Edit, EditProps, SimpleForm } from 'react-admin';

import Toolbar from '@components/form/Toolbar';
import InputGuesser from '@components/form/InputGuesser';

const SelloPaymentMethodEdit = (props: EditProps) => {
    return (
        <Edit {...props}>
            <SimpleForm toolbar={<Toolbar disableDelete />}>
                <InputGuesser source="name" />
                <InputGuesser source="daysToSettlement" />
            </SimpleForm>
        </Edit>
    );
};

export default SelloPaymentMethodEdit;
