import { MouseEvent, useRef, useState } from 'react';
import { useTranslate } from 'react-admin';
import { Box, IconButton, Menu, MenuItem } from '@material-ui/core';
import MoreVertIcon from '@material-ui/icons/MoreVert';

import BarcodeScannerInput from '@components/input/BarcodeScannerInput';
import IceCatDialogIconButton, { IceCatDialogIconButtonHandle } from '../button/IceCatDialogIconButton';
import SuggestCloneIconButton, { SuggestCloneIconButtonHandle } from '../button/SuggestCloneIconButton';
import ChatGPTFormButton from '../button/ChatGPT/ChatGPTFormButton';

import { useIsFeatureDisabled } from '@js/context/AppConfigContext';
import { useResourceFeatures } from '@js/context/UserPermissionsContext';
import { FormCompletionButtonHandle } from '../button/ChatGPT/common';

type Props = {
    source: string;
    fullWidth?: boolean;
    resource?: string;
};

const EanInput = (props: Props) => {
    return (
        <Box display="flex">
            <BarcodeScannerInput {...props} />
            <Box pt={1}>
                <MenuItems />
            </Box>
        </Box>
    );
};

const MenuItems = () => {
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const translate = useTranslate();
    const isFeatureDisabled = useIsFeatureDisabled();
    const features = useResourceFeatures('reclamations');

    const iceCatButtonRef = useRef<IceCatDialogIconButtonHandle>(null);
    const suggestCloneButtonRef = useRef<SuggestCloneIconButtonHandle>(null);
    const chatGPTFormCompletionButtonRef = useRef<FormCompletionButtonHandle>(null);

    const handleClick = (event: MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    return (
        <>
            <IconButton onClick={handleClick}>
                <MoreVertIcon />
            </IconButton>
            <Menu anchorEl={anchorEl} open={!!anchorEl} onClose={handleClose} keepMounted>
                <MenuItem
                    onClick={() => {
                        setAnchorEl(null);
                        if (anchorEl) suggestCloneButtonRef.current?.open();
                    }}
                >
                    <SuggestCloneIconButton suggestSource="ean" ref={suggestCloneButtonRef} />
                    {translate('app.action.copy')}
                </MenuItem>
                {!isFeatureDisabled('Icecat') && (
                    <MenuItem
                        onClick={() => {
                            setAnchorEl(null);
                            if (anchorEl) iceCatButtonRef.current?.open();
                        }}
                    >
                        <IceCatDialogIconButton gtinSource="ean" ref={iceCatButtonRef} />
                        {translate('app.action.icecat')}
                    </MenuItem>
                )}
                {!features.disableChatGptManager && (
                    <MenuItem
                        onClick={() => {
                            if (anchorEl) chatGPTFormCompletionButtonRef.current?.open();
                            setAnchorEl(null);
                        }}
                    >
                        <ChatGPTFormButton ref={chatGPTFormCompletionButtonRef} />
                        {translate('app.label.chat_gpt_manager')}
                    </MenuItem>
                )}
            </Menu>
        </>
    );
};

export default EanInput;
