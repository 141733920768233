import {
    Record as RaRecord,
    SimpleList,
    SimpleListProps,
    useLocale,
    useRecordContext,
    useTranslate,
} from 'react-admin';
import { get } from 'lodash';
import { makeStyles } from '@material-ui/core/styles';

import RowActions from '@components/list/RowActions';

import { useHydraSchemaContext } from '@js/context/HydraSchemaContext';
import useResourceSchema from '@js/hooks/useResourceSchema';
import { ApiRecord } from '@js/interfaces/ApiRecord';

interface Props<RecordType extends RaRecord> extends SimpleListProps<RecordType> {
    subResourceActions?: boolean;
    hasDelete?: boolean;
}

const SimpleListGuesser = <RecordType extends RaRecord>({
    subResourceActions,
    hasDelete,
    ...props
}: Props<RecordType>) => {
    const { schemaAnalyzer } = useHydraSchemaContext();
    const schema = useResourceSchema(props);
    const translate = useTranslate();
    const fieldName = schemaAnalyzer.getFieldNameFromSchema(schema);
    const locale = useLocale();

    return (
        <SimpleList
            primaryText={(record) => {
                return get(record, fieldName) || `[${translate('app.label.not_defined')}]`;
            }}
            secondaryText={(record) => {
                return record.createdAt && new Date(record.createdAt).toLocaleString(locale);
            }}
            tertiaryText={
                <DefaultTertiaryText
                    subResourceActions={subResourceActions}
                    hasDelete={hasDelete}
                    resource={props.resource}
                    basePath={props.basePath}
                />
            }
            linkType={false}
            {...props}
        />
    );
};

const DefaultTertiaryText = (props: {
    resource?: string;
    record?: RaRecord;
    basePath?: string;
    subResourceActions?: boolean;
    hasDelete?: boolean;
}) => {
    const classes = useStyles();
    const record = useRecordContext(props);

    return (
        <span className={classes.tertiary}>
            <RowActions {...props} record={record as ApiRecord} />
        </span>
    );
};

const useStyles = makeStyles({
    tertiary: { float: 'right' },
});

export default SimpleListGuesser;
