import { FC, useRef } from 'react';
import { MenuItem, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@material-ui/core';
import { useGetIdentity, useGetResourceLabel } from 'react-admin';
import { makeStyles } from '@material-ui/core/styles';
import EditIcon from '@material-ui/icons/Create';
import DeleteIcon from '@material-ui/icons/Delete';

import MenuButton, { MenuHandle } from '@components/button/MenuButton';
import ReferenceOneRecord from '@components/detail/ReferenceOneRecord';

import { toLocaleString } from '@js/utility/dateUtility';
import { secondsToHumanTime } from '@js/utility/numberFormatUtility';
import useTranslateResourceField from '@js/hooks/useTranslateResourceField';
import { useUserPermissions } from '@js/context/UserPermissionsContext';
import { ProcessingTimeEmbedded } from '@js/interfaces/processingtime';
import { useFormatCentsPrice } from '@js/hooks/useFormatPrice';

type ProcessingTimeItem = PartialBy<ProcessingTimeEmbedded, '@id'>;

export interface ProcessingTimeTableProps {
    items: Record<string, ProcessingTimeItem>;
    onEdit?: (id: string, record: ProcessingTimeItem) => void;
    onDelete?: (id: string, record: ProcessingTimeItem) => void;
}

interface ItemRowProps extends Omit<ProcessingTimeTableProps, 'items'> {
    item: ProcessingTimeItem;
    id: string;
}

const useStyles = makeStyles({
    container: {
        maxHeight: '450px',
    },
});

const ItemRow: FC<ItemRowProps> = ({ item, onEdit, onDelete, id }) => {
    const { createdBy, createdAt, description, userGroup, autoCreationReason, cost, time } = item;
    const permissions = useUserPermissions();
    const { identity } = useGetIdentity();
    const ref = useRef<MenuHandle>(null);
    const formatPrice = useFormatCentsPrice();

    const isAutoCreated = !!autoCreationReason;
    const hasAccessToActions = permissions.isAdmin || (createdBy === identity?.id && !isAutoCreated);

    return (
        <TableRow>
            <TableCell>{toLocaleString(createdAt)}</TableCell>
            <TableCell>
                <ReferenceOneRecord resource="users" id={createdBy} />
            </TableCell>
            <TableCell>
                <ReferenceOneRecord resource="user_groups" id={userGroup} />
            </TableCell>
            <TableCell>{`${secondsToHumanTime(time ?? 0)} h`}</TableCell>
            <TableCell>{`${formatPrice(cost ?? 0)}`}</TableCell>
            <TableCell>{description}</TableCell>
            {(onEdit || onDelete) && (
                <TableCell align="right">
                    {hasAccessToActions && (
                        <MenuButton ref={ref}>
                            {onEdit && (
                                <MenuItem
                                    onClick={() => {
                                        onEdit(id, item);
                                        ref.current?.close();
                                    }}
                                >
                                    <EditIcon fontSize="small" color="secondary" />
                                </MenuItem>
                            )}
                            {onDelete && (
                                <MenuItem
                                    onClick={() => {
                                        onDelete(id, item);
                                        ref.current?.close();
                                    }}
                                >
                                    <DeleteIcon fontSize="small" color="error" />
                                </MenuItem>
                            )}
                        </MenuButton>
                    )}
                </TableCell>
            )}
        </TableRow>
    );
};

const ProcessingTimeTable: FC<ProcessingTimeTableProps> = ({ items, onEdit, onDelete }) => {
    const translateResourceField = useTranslateResourceField('processing_times');
    const getResourceLabel = useGetResourceLabel();
    const classes = useStyles();

    return (
        <TableContainer component={Paper} className={classes.container}>
            <Table stickyHeader size="small">
                <TableHead>
                    <TableRow>
                        <TableCell>{translateResourceField('createdAt')}</TableCell>
                        <TableCell>{translateResourceField('createdBy')}</TableCell>
                        <TableCell>{getResourceLabel('user_groups', 1)}</TableCell>
                        <TableCell>{translateResourceField('time')}</TableCell>
                        <TableCell>{translateResourceField('cost')}</TableCell>
                        <TableCell>{translateResourceField('description')}</TableCell>
                        {(onEdit || onDelete) && <TableCell />}
                    </TableRow>
                </TableHead>
                <TableBody>
                    {Object.keys(items).map((id) => (
                        <ItemRow key={id} id={id} item={items[id]} onEdit={onEdit} onDelete={onDelete} />
                    ))}
                </TableBody>
            </Table>
        </TableContainer>
    );
};

export default ProcessingTimeTable;
