import { Box, IconButton } from '@material-ui/core';
import {
    Datagrid,
    DateField,
    List,
    ListProps,
    Record as RaRecord,
    SimpleList,
    useRecordContext,
    useTranslate,
} from 'react-admin';
import GetAppIcon from '@material-ui/icons/GetApp';

import DeleteIconButton from '@components/button/DeleteIconButton';
import DatagridBodyWithMercure from '@components/list/DatagridBodyWithMercure';

import ProgressCircularField from './ProgressCircularField';
import FilenameField from './FilenameField';

import { downloadFileUrl } from '@js/utility/downloadFileUtil';
import useIsSmallScreen from '@js/hooks/useIsSmallScreen';

import { ErrandsExport } from '@js/interfaces/ErrandsExport';

const rowStyle = (record?: RaRecord) => {
    const errandExport = record as ErrandsExport | undefined;
    if (!errandExport) return {};

    return {
        ...(!errandExport.downloadedAt && errandExport.status === 'completed' && { backgroundColor: '#efe' }),
    };
};

const ErrandsExportList = (props: ListProps) => {
    // Add a dummy filter to prevent caching
    const filters = { _: new Date().getTime() };
    const isSmall = useIsSmallScreen();

    return (
        <List {...props} sort={{ field: 'createdAt', order: 'desc' }} exporter={false} filter={filters}>
            {isSmall ? (
                <SimpleList<ErrandsExport>
                    linkType={false}
                    rowStyle={rowStyle}
                    primaryText={(record) => <FilenameField record={record} />}
                    secondaryText={(record) => <DateField source="createdAt" record={record} showTime />}
                    tertiaryText={
                        <Box display="flex" justifyContent="end" sx={{ float: 'right' }}>
                            <DownloadAction label="app.action.download" />
                            <DeleteIconButton />
                        </Box>
                    }
                />
            ) : (
                <Datagrid body={DatagridBodyWithMercure} rowStyle={rowStyle}>
                    <DateField source="createdAt" showTime />
                    <ProgressCircularField />
                    <FilenameField />
                    <DownloadAction label="app.action.download" />
                    <RowActions />
                </Datagrid>
            )}
        </List>
    );
};

const DownloadAction = (props: { label: string; record?: ErrandsExport }) => {
    const record = useRecordContext(props);
    const translate = useTranslate();

    if (!record) return null;

    const handleClick = () => {
        downloadFileUrl(`${record.id}/download`);
    };

    return (
        <IconButton
            size="small"
            disabled={record.status !== 'completed'}
            title={translate('app.action.download')}
            onClick={handleClick}
        >
            <GetAppIcon />
        </IconButton>
    );
};

const RowActions = () => {
    return (
        <Box display="flex" justifyContent="end">
            <DeleteIconButton />
        </Box>
    );
};

export default ErrandsExportList;
