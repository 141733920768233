import TreeInput from '@components/input/TreeInput';
import { Record as RaRecord } from 'react-admin';

type Props = {
    source: string;
    record?: RaRecord;
    multiple?: boolean;
    fullWidth?: boolean;
    alwaysOn?: boolean;
};

const CategoryTreeInput = (props: Props) => {
    return (
        <TreeInput
            reference="categories"
            parentsField="parents"
            parentField="parent"
            childrenField="children"
            filter={{ entrypoint: true }}
            selectable={(category) => category.children.length === 0}
            {...props}
        />
    );
};

export default CategoryTreeInput;
