import { useTranslate } from 'react-admin';
import useTranslateResourceField from '@js/hooks/useTranslateResourceField';

const useDisableReason = ({ title, ean }: { title?: string; ean?: string }) => {
    const translate = useTranslate();
    const getFieldLabel = useTranslateResourceField();

    return (
        !title &&
        !ean &&
        `${translate('app.error.missing_required_fields')}: "${getFieldLabel('title')}, ${getFieldLabel('ean')}"`
    );
};

export default useDisableReason;
