import { Reclamation } from '@js/interfaces/reclamation';

export type CompletionField = Extract<
    keyof Reclamation,
    'insurancePrice' | 'shortDescription' | 'longDescription' | 'weight' | 'measure'
>;

export type FormCompletionButtonHandle = {
    open: () => void;
    close: () => void;
};

export const COMPLETION_FIELDS: CompletionField[] = [
    'insurancePrice',
    'shortDescription',
    'longDescription',
    'weight',
    'measure',
];
