import { useState } from 'react';
import { ImageInput, ImageInputProps, useTranslate } from 'react-admin';
import { Checkbox, FormControlLabel, makeStyles, Theme } from '@material-ui/core';

import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import CheckBoxIcon from '@material-ui/icons/CheckBox';

import { useIsMobile } from '@js/context/AppConfigContext';

const VideoImageInput = (props: ImageInputProps) => {
    const isMobile = useIsMobile();
    const classes = useStyles({ isMobile });
    const [useCamera, setUseCamera] = useState(isMobile);
    const translate = useTranslate();

    return (
        <div className={classes.root}>
            {isMobile && (
                <FormControlLabel
                    className={classes.checkbox}
                    control={
                        <Checkbox
                            checked={useCamera}
                            onChange={(e) => setUseCamera(e.target.checked)}
                            icon={<CheckBoxOutlineBlankIcon fontSize="small" />}
                            checkedIcon={<CheckBoxIcon fontSize="small" />}
                        />
                    }
                    label={translate('app.label.use_camera')}
                />
            )}
            <ImageInput
                {...props}
                className={classes.imageInput}
                options={
                    useCamera
                        ? {
                              inputProps: {
                                  capture: 'environment',
                              },
                          }
                        : undefined
                }
            />
        </div>
    );
};

const useStyles = makeStyles<Theme, { isMobile: boolean }>({
    root: {
        position: 'relative',
    },
    checkbox: {
        position: 'absolute',
        top: 0,
        right: 0,
        zIndex: 1,
    },
    imageInput: ({ isMobile }) => ({
        '& .previews > div[class^="RaFileInput-removeButton"] > button': {
            zIndex: 1,
            ...(isMobile && { opacity: 1 }),
        },
    }),
});

export default VideoImageInput;
