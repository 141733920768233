import { useRef, useState } from 'react';
import { useTranslate, Validator } from 'react-admin';
import { Reclamation } from '@js/interfaces/reclamation';
import { Box, IconButton, Menu, MenuItem } from '@material-ui/core';
import MoreVertIcon from '@material-ui/icons/MoreVert';

import TextAutocompleteInput from '@components/input/TextAutocompleteInput';

import SuggestCloneIconButton, { SuggestCloneIconButtonHandle } from '../button/SuggestCloneIconButton';
import ChatGPTFormButton from '../button/ChatGPT/ChatGPTFormButton';

import useInputAutoValidator from '@js/hooks/useInputAutoValidator';
import { useResourceFeatures } from '@js/context/UserPermissionsContext';
import { FormCompletionButtonHandle } from '../button/ChatGPT/common';

type Props = {
    source: string;
    fullWidth?: boolean;
    resource?: string;
    record?: Reclamation;
    validate?: Validator | Validator[];
};

const TitleInput = (props: Props) => {
    const validate = useInputAutoValidator(props);

    return (
        <Box display="flex">
            <TextAutocompleteInput {...props} validate={validate} queryField="title" />
            <Box mt={1}>
                <MenuItems source={props.source} />
            </Box>
        </Box>
    );
};

const MenuItems = ({ source }: { source: string }) => {
    const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
    const translate = useTranslate();
    const features = useResourceFeatures('reclamations');

    const chatGPTFormCompletionButtonRef = useRef<FormCompletionButtonHandle>(null);
    const suggestCloneButtonRef = useRef<SuggestCloneIconButtonHandle>(null);

    const handleMenuClose = () => {
        setAnchorEl(null);
    };

    return (
        <>
            <IconButton onClick={(e) => setAnchorEl(e.currentTarget)}>
                <MoreVertIcon />
            </IconButton>
            <Menu anchorEl={anchorEl} open={!!anchorEl} onClose={handleMenuClose} keepMounted>
                {!features.disableChatGptManager && (
                    <MenuItem
                        onClick={() => {
                            if (anchorEl) chatGPTFormCompletionButtonRef.current?.open();
                            setAnchorEl(null);
                        }}
                    >
                        <ChatGPTFormButton ref={chatGPTFormCompletionButtonRef} />
                        {translate('app.label.chat_gpt_manager')}
                    </MenuItem>
                )}
                <MenuItem
                    onClick={() => {
                        if (anchorEl) suggestCloneButtonRef.current?.open();
                        setAnchorEl(null);
                    }}
                >
                    <SuggestCloneIconButton suggestSource={source} ref={suggestCloneButtonRef} />
                    {translate('app.action.copy')}
                </MenuItem>
            </Menu>
        </>
    );
};

export default TitleInput;
