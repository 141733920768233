import { useCallback } from 'react';
import { minValue, SimpleFormProps, useTranslate } from 'react-admin';

import SimpleFormGuesser from '@components/form/SimpleFormGuesser';
import ReferenceRecursiveSelectInput from '@components/input/ReferenceRecursiveSelectInput';
import InputGuesser from '@components/form/InputGuesser';
import CategoryTreeInput from '@components/input/CategoryTreeInput';

import { minutes } from '@js/validator/minutes';
import useTranslateResourceField from '@js/hooks/useTranslateResourceField';
import { ProcessingTimeDefaults } from '@js/interfaces/ProcessingTimeDefaults';

const ProcessingTimeDefaultsForm = (props: Omit<SimpleFormProps, 'children'>) => {
    const getFieldLabel = useTranslateResourceField();
    const validateForm = useValidateForm();

    return (
        <SimpleFormGuesser {...props} validate={validateForm} fullWidth>
            <InputGuesser
                source="timeInMinutes"
                validate={[minutes(), minValue(1)]}
                label={getFieldLabel('timePlaceholder', 'processing_times')}
                fullWidth
            />
            <CategoryTreeInput source="firstTimeAssignedCategories" multiple fullWidth />
            <ReferenceRecursiveSelectInput source="statuses" multiple fullWidth />
        </SimpleFormGuesser>
    );
};

const useValidateForm = () => {
    const translate = useTranslate();
    const getFieldLabel = useTranslateResourceField();

    return useCallback(
        (values: Partial<ProcessingTimeDefaults>) => {
            const errors: Partial<Record<keyof ProcessingTimeDefaults, string>> = {};

            if (!values.timeInMinutes && !values.cost) {
                const message = translate('app.validation.one_of_fields_required', {
                    fields: `${getFieldLabel('timeInMinutes')}, ${getFieldLabel('cost')}`,
                });
                errors.timeInMinutes = message;
                errors.cost = message;
            }

            return errors;
        },
        [getFieldLabel, translate],
    );
};

export default ProcessingTimeDefaultsForm;
