import { FC, useRef } from 'react';
import { Box, MenuItem } from '@material-ui/core';
import { Datagrid as RaDatagrid, DatagridProps, ListProps, Pagination, useResourceDefinition } from 'react-admin';

import ListGuesser, { useListGuesserControllerContext } from '@components/list/ListGuesser';
import SimpleListGuesser from '@components/list/SimpleListGuesser';
import DatagridBodyWithMercure from '@components/list/DatagridBodyWithMercure';
import ListStickyToolbar, { STICKY_CLASS_NAME } from '@components/list/ListStickyToolbar';
import LockRecordIcon from '@components/detail/LockRecordIcon';
import ShowIconButton from '@components/button/ShowIconButton';
import EditIconButton from '@components/button/EditIconButton';
import MenuButton, { MenuHandle } from '@components/button/MenuButton';

import ErrandListActions from './ErrandListActions';
import ExpandPanel from './ExpandPanel';
import ErrandFieldGuesser from '../field/ErrandFieldGuesser';
import BulkActionButtons from './BulkActionButtons';
import ManualSaleButton from './ManualSaleButton';
import ReturnedItemButton from './ReturnedItemButton';
import IceCatBatchIconButton from '../button/IceCatBatchIconButton';

import useFilters from './useFilters';
import { Errand } from '@js/interfaces/errand';
import useIsSmallScreen from '@js/hooks/useIsSmallScreen';
import { isFieldSortable } from '@js/utility/fieldsUtil';
import { useResourceFilterDefaultValues, useResourcePermissions } from '@js/context/UserPermissionsContext';

const ErrandList = (props: ListProps) => {
    const isSmall = useIsSmallScreen();
    const filters = useFilters(props);
    const filterDefaultValues = useResourceFilterDefaultValues(['lastStatus'], props);

    return (
        <ListGuesser
            {...props}
            list={ListStickyToolbar}
            hideFields={['statusNotifications']}
            actions={<ErrandListActions />}
            filters={filters}
            filterDefaultValues={filterDefaultValues}
            bulkActionButtons={<BulkActionButtons />}
            pagination={<Pagination rowsPerPageOptions={[5, 10, 25, 50, 100, 200]} />}
        >
            {isSmall ? (
                <SimpleListGuesser<Errand>
                    linkType={false}
                    secondaryText={(record) => record.composedIdentifier}
                    tertiaryText={
                        <Box component="span" sx={{ float: 'right' }}>
                            <RowActions />
                        </Box>
                    }
                />
            ) : (
                <Datagrid />
            )}
        </ListGuesser>
    );
};

const Datagrid: FC<DatagridProps> = (props) => {
    const { selectedFields, schema } = useListGuesserControllerContext();

    return (
        <RaDatagrid
            {...props}
            expand={ExpandPanel}
            rowStyle={(record) => ({
                ...((record as Errand)?.reclamationsCount > 1 && { backgroundColor: '#efe' }),
            })}
            body={DatagridBodyWithMercure}
            hasBulkActions
            className={STICKY_CLASS_NAME}
        >
            {selectedFields.map((field) => (
                <ErrandFieldGuesser
                    key={field.name}
                    source={field.name}
                    field={field}
                    sortable={isFieldSortable(field, schema)}
                />
            ))}
            <RowActions />
        </RaDatagrid>
    );
};

const RowActions = (props: { record?: Errand; resource?: string }) => {
    const { isListActionEnabled } = useResourcePermissions(props);
    const { hasShow, hasEdit } = useResourceDefinition(props);

    const menuRef = useRef<MenuHandle>(null);
    const handleClose = () => menuRef.current?.close();

    return (
        <Box display="flex" justifyContent="end">
            <LockRecordIcon />
            {hasEdit && <EditIconButton {...props} />}
            <MenuButton ref={menuRef} keepMounted>
                {hasShow && (
                    <MenuItem onClick={handleClose}>
                        <ShowIconButton {...props} />
                    </MenuItem>
                )}
                {isListActionEnabled('manual_sale') && (
                    <MenuItem onClick={handleClose}>
                        <ManualSaleButton {...props} />
                    </MenuItem>
                )}
                {isListActionEnabled('returned_item') && (
                    <MenuItem onClick={handleClose}>
                        <ReturnedItemButton {...props} />
                    </MenuItem>
                )}
                <MenuItem onClick={handleClose}>
                    <IceCatBatchIconButton {...props} />
                </MenuItem>
            </MenuButton>
        </Box>
    );
};

export default ErrandList;
