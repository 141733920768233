import { ComponentType, createContext, ReactElement, ReactNode, useContext } from 'react';
import { Datagrid, DatagridProps, List, ListProps } from 'react-admin';

import FieldGuesser from '@components/field/FieldGuesser';
import RowActions from '@components/list/RowActions';
import SimpleListGuesser from '@components/list/SimpleListGuesser';
import BulkActionButtons from '@components/list/BulkActionButtons';
import ListActions from '@components/list/ListActions';

import useIsSmallScreen from '@js/hooks/useIsSmallScreen';
import useListGuesserController from '@js/hooks/useListGuesserController';

import { getOverrideFields, isFieldSortable } from '@js/utility/fieldsUtil';
import useTranslateResourceField from '@js/hooks/useTranslateResourceField';

interface Props extends ListProps {
    children?: ReactElement;
    list?: ComponentType<ListProps & { children: ReactElement }>;
    hideFields?: string[];
}

type ListGuesserControllerValue = ReturnType<typeof useListGuesserController>;

export const ListGuesserControllerContext = createContext<ListGuesserControllerValue>(undefined!);

export const useListGuesserControllerContext = () => {
    return useContext(ListGuesserControllerContext);
};

export const DatagridGuesser = ({
    children,
    rowActions,
    overrideFieldTranslateResource,
    ...props
}: DatagridProps & { rowActions?: ReactElement; overrideFieldTranslateResource?: string }) => {
    const { selectedFields, schema } = useListGuesserControllerContext();
    const overrideFields = getOverrideFields(children);
    const getFieldLabel = useTranslateResourceField(overrideFieldTranslateResource);

    return (
        <Datagrid {...props}>
            {selectedFields.map((field) => {
                return (
                    overrideFields[field.name] ?? (
                        <FieldGuesser
                            key={field.name}
                            label={getFieldLabel(field.name)}
                            source={field.name}
                            sortable={isFieldSortable(field, schema)}
                            field={field}
                        />
                    )
                );
            })}
            {rowActions ?? <RowActions />}
        </Datagrid>
    );
};

export const ListGuesserBase = ({
    children,
    hideFields,
    ...props
}: {
    children: ReactNode;
    resource?: string;
    hideFields?: string[];
}) => {
    const controllerProps = useListGuesserController(props, hideFields);

    return (
        <ListGuesserControllerContext.Provider value={controllerProps}>
            {children}
        </ListGuesserControllerContext.Provider>
    );
};

const ListGuesser = ({ children, hideFields, list, sort = { field: 'createdAt', order: 'desc' }, ...props }: Props) => {
    const isSmall = useIsSmallScreen();
    const ListComponent = list || List;

    return (
        <ListGuesserBase hideFields={hideFields} resource={props.resource}>
            <ListComponent
                exporter={false}
                sort={sort}
                bulkActionButtons={<BulkActionButtons />}
                actions={<ListActions />}
                {...props}
            >
                {children || (isSmall ? <SimpleListGuesser /> : <DatagridGuesser />)}
            </ListComponent>
        </ListGuesserBase>
    );
};

export default ListGuesser;
