import ShortTextIcon from '@material-ui/icons/ShortText';

import PredefinedTextForm from './PredefinedTextForm';
import PredefinedTextList from './PredefinedTextList';

export default {
    list: PredefinedTextList,
    show: null,
    icon: ShortTextIcon,
    options: {
        form: PredefinedTextForm,
    },
};
