import { FC } from 'react';
import {
    CardContentInner,
    Edit,
    EditActionsProps,
    EditProps,
    FormWithRedirect,
    SaveButton,
    Toolbar,
    useNotify,
    useRedirect,
} from 'react-admin';
import { Box } from '@material-ui/core';

import AlertLockEditRecord from '@components/detail/AlertLockEditRecord';
import LockedFormSafeguard from '@components/form/LockedFormSafeguard';
import RecordTitle from '@components/detail/RecordTitle';
import CancelButton from '@components/button/CancelButton';

import DeleteButton from './button/DeleteButton';
import ReclamationForm from './ReclamationForm';

import useAutoTrackTimeController from '@js/hooks/useAutoTrackTimeController';
import useUnmountEffect from '@js/hooks/useUnmountEffect';
import useNotifyHttpError from '@js/hooks/useNotifyHttpError';

const Actions: FC<EditActionsProps> = ({ data }) => {
    return (
        <>
            {data && (
                <Box mb={1}>
                    <AlertLockEditRecord id={data.id} />
                </Box>
            )}
        </>
    );
};

const ReclamationEdit: FC<EditProps> = (props) => {
    const notify = useNotify();
    const notifyFailure = useNotifyHttpError();
    const redirect = useRedirect();
    const { basePath, id } = props;
    const { saveEditSession, saveCancelSession } = useAutoTrackTimeController();

    useUnmountEffect(() => {
        saveCancelSession({ reclamation: id });
    });

    return (
        <LockedFormSafeguard {...props}>
            <Edit
                {...props}
                onSuccess={() => {
                    notify('ra.notification.updated', {
                        type: 'info',
                        messageArgs: { smart_count: 1 },
                        undoable: true,
                    });
                    saveEditSession({ reclamation: id });
                    redirect('list', basePath);
                }}
                onFailure={notifyFailure}
                actions={<Actions />}
                title={<RecordTitle />}
                mutationMode="pessimistic"
            >
                <FormWithRedirect
                    render={(formProps) => {
                        return (
                            <form>
                                <CardContentInner>
                                    <ReclamationForm id={props.id} resource={props.resource} />
                                </CardContentInner>
                                <Toolbar
                                    record={formProps.record}
                                    pristine={formProps.pristine}
                                    validating={formProps.validating}
                                    invalid={formProps.invalid}
                                    handleSubmit={formProps.handleSubmit}
                                    handleSubmitWithRedirect={formProps.handleSubmitWithRedirect}
                                    saving={formProps.saving}
                                    basePath={basePath}
                                >
                                    <Buttons />
                                </Toolbar>
                            </form>
                        );
                    }}
                />
            </Edit>
        </LockedFormSafeguard>
    );
};

const Buttons = (props: { pristine?: boolean; basePath?: string; [key: string]: any }) => {
    return (
        <Box display="flex" flexGrow={1} justifyContent="space-between">
            <Box display="flex" gridGap="8px" alignItems="center">
                <SaveButton {...props} disabled={props.pristine} />
                <CancelButton to={props.basePath!} requireConfirm={!props.pristine} />
            </Box>
            <DeleteButton deleteWithConfirmButtonProps={props} />
        </Box>
    );
};

export default ReclamationEdit;
