import { useEffect, useRef } from 'react';
import {
    FieldTitle,
    InputHelperText,
    Labeled,
    LinearProgress,
    Record as RaRecord,
    SortPayload,
    useGetList,
    useGetMany,
    useGetManyReference,
    useInput,
    useRecordContext,
    Validator,
} from 'react-admin';
import {
    Checkbox,
    FormControl,
    FormControlLabel,
    FormGroup,
    FormHelperText,
    FormLabel,
    Theme,
} from '@material-ui/core';
import get from 'lodash/get';
import { makeStyles } from '@material-ui/core/styles';

import TreeView, { TreeItem, useTreeItem } from '@js/components/mui/TreeView';
import AlertEmptyResource from '@components/list/AlertEmptyResource';

import useResourceFieldName from '@js/hooks/useResourceFieldName';
import { Category } from '@js/interfaces/category';
import { Iri } from '@js/interfaces/ApiRecord';

type Props<RecordType extends RaRecord> = {
    source: string;
    reference: string;
    parentField: string; // Has Iri of parent
    parentsField: string; // Has Iris of all parents
    childrenField: string; // Has Iris of children
    selectable?: (record: RecordType) => boolean;
    pagination?: { page: number; perPage: number };
    multiple?: boolean;
    sort?: SortPayload;
    label?: string;
    resource?: string;
    record?: RecordType;
    validate?: Validator | Validator[];
    helperText?: string | false;
    maxHeight?: number;
    fullWidth?: boolean;
    filter?: Record<string, any>;
};

const TreeInput = <RecordType extends RaRecord>({
    reference,
    parentField,
    parentsField,
    childrenField,
    selectable,
    multiple,
    label,
    helperText,
    resource,
    pagination = { page: 1, perPage: 99 },
    filter = {},
    ...props
}: Props<RecordType>) => {
    const fieldName = useResourceFieldName(reference);

    const { source, sort = { field: fieldName, order: 'ASC' } } = props;
    const classes = useInputStyles(props);

    const {
        isRequired,
        input,
        meta: { error, submitError, touched },
    } = useInput(props);
    const value = multiple
        ? Array.isArray(input.value)
            ? (input.value as string[])
            : []
        : typeof input.value === 'string'
        ? input.value
        : null;
    const initialValueRef = useRef(Array.isArray(value) ? value : value ? [value] : []);

    // Load record
    const {
        loaded: isLoadedValue,
        data: valueData,
        error: valueFetchError,
    } = useGetMany(reference, initialValueRef.current);

    // Load a root list
    const {
        loaded: isLoadedRootList,
        data: rootListData,
        ids: rootIds,
    } = useGetList<Category>(reference, pagination, sort, {
        [`exists[${parentField}]`]: false,
        ...filter,
    });

    useEffect(() => {
        if (!rootListData) return;
        if (Object.values(rootListData).some((item) => get(item, parentField))) {
            throw new Error("Root items can't have parents");
        }
    }, [parentField, rootListData]);

    if (!isLoadedRootList || !isLoadedValue) {
        return (
            <Labeled
                label={label}
                source={source}
                resource={resource}
                className={`ra-input ra-input-${source}`}
                isRequired={isRequired}
            >
                <LinearProgress />
            </Labeled>
        );
    }

    const defaultExpanded = (valueData ?? [])
        .filter(Boolean)
        .map((item) => {
            const parents = get(item, parentsField, []) as Iri[];
            return [...parents, item.id.toString()];
        })
        .flat();

    const handleSelect = (id: string) => {
        if (multiple && Array.isArray(value)) {
            input.onChange(value.includes(id) ? value.filter((value) => value !== id) : [...value, id]);
        } else {
            input.onChange(id === value ? null : id);
        }
    };

    const rootRecords = rootIds.map((id) => rootListData[id]).filter(Boolean);
    const noResults = rootRecords.length === 0;

    return (
        <FormControl
            margin="dense"
            className={`ra-input ra-input-${source} ${classes.formGroup}`}
            error={!!(touched && (error || submitError))}
        >
            <FormLabel component="legend" className={classes.label}>
                <FieldTitle label={label} source={source} resource={resource} isRequired={isRequired} />
            </FormLabel>
            <FormGroup>
                {noResults ? (
                    <AlertEmptyResource resource={reference} />
                ) : (
                    <TreeView defaultExpanded={defaultExpanded}>
                        {rootRecords.map((record) => (
                            <RecordTreeItem<RecordType>
                                key={record.id}
                                record={record}
                                value={value}
                                onSelect={handleSelect}
                                reference={reference}
                                sort={sort}
                                pagination={pagination}
                                parentField={parentField}
                                childrenField={childrenField}
                                selectable={selectable}
                                fieldName={fieldName}
                            />
                        ))}
                    </TreeView>
                )}
            </FormGroup>
            <FormHelperText>
                <InputHelperText
                    touched={!!touched}
                    error={error || submitError || valueFetchError}
                    helperText={helperText}
                />
            </FormHelperText>
        </FormControl>
    );
};

const RecordTreeItem = <RecordType extends RaRecord>(
    props: {
        record: RaRecord;
        value: string[] | string | null;
        onSelect: (id: string) => void;
        pagination: { page: number; perPage: number };
        sort: SortPayload;
        fieldName: string;
    } & Pick<Props<RecordType>, 'reference' | 'selectable' | 'parentField' | 'childrenField'>,
) => {
    const { record, value, onSelect, sort, reference, selectable, pagination, parentField, childrenField, fieldName } =
        props;
    const id = record.id.toString();

    const currentRecord = useRecordContext();
    const { isExpanded, onExpand } = useTreeItem(id);
    const classes = useItemStyles();

    const childrenIris = get(record, childrenField) as Iri[];
    if (!Array.isArray(childrenIris)) throw new Error(`Field ${childrenField} must be an array of children IRIs`);

    const isSelectable = selectable?.(record as RecordType) ?? true;
    const hasChildren = childrenIris.length > 0;
    const disabled = currentRecord?.id === record.id; // Prevent selecting itself
    const checked = Array.isArray(value) ? value.includes(id) : value === id;
    const title = get(record, fieldName, '[Not title]');
    const isEnabledLoading = hasChildren && isExpanded && !disabled;

    const {
        loaded: isChildrenLoaded,
        data: children,
        ids: childrenIds,
    } = useGetManyReference(reference, parentField, id, pagination, sort, {}, reference, {
        enabled: isEnabledLoading,
    });

    return (
        <TreeItem
            id={id}
            label={
                !isSelectable ? (
                    <label className={classes.label} onClick={onExpand}>
                        {title}
                    </label>
                ) : (
                    <FormControlLabel
                        className={classes.formControl}
                        control={
                            <Checkbox
                                size="small"
                                className={classes.checkbox}
                                checked={checked}
                                disabled={disabled}
                                onChange={() => onSelect(id)}
                            />
                        }
                        label={title}
                    />
                )
            }
            expandable={hasChildren}
            loading={!isChildrenLoaded && isEnabledLoading}
        >
            {(childrenIds as Iri[])
                .map((id) => children[id] as Category)
                .filter(Boolean)
                .map((child) => (
                    <RecordTreeItem key={child.id} {...props} record={child} />
                ))}
        </TreeItem>
    );
};

const useInputStyles = makeStyles<Theme, { maxHeight?: number; fullWidth?: boolean }>((theme) => ({
    formGroup: ({ maxHeight, fullWidth }) => ({
        border: '1px solid rgba(0, 0, 0, 0.1)',
        borderRadius: '5px',
        padding: '12px',
        boxSizing: 'border-box',
        overflow: 'auto',
        ...(fullWidth && { width: '100%' }),
        ...(maxHeight && { maxHeight, overflowY: 'scroll' }),
    }),
    label: {
        transform: 'translate(0, 8px) scale(0.75)',
        transformOrigin: 'top left',
        marginBottom: theme.spacing(1),
    },
}));

const useItemStyles = makeStyles({
    checkbox: {
        padding: 0,
    },
    formControl: {
        marginLeft: 0,
        whiteSpace: 'nowrap',
    },
    label: {
        display: 'inline-flex',
        alignItems: 'center',
        verticalAlign: 'middle',
        cursor: 'pointer',
    },
});

export default TreeInput;
