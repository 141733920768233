import { FC } from 'react';
import {
    FormTab,
    required,
    SimpleForm,
    SimpleFormProps,
    TabbedForm,
    TabbedFormProps,
    useGetResourceLabel,
    useRecordContext,
} from 'react-admin';
import { Field } from '@api-platform/api-doc-parser';
import { Box, FormControl, FormGroup } from '@material-ui/core';
import { chunk } from 'lodash';

import HexInputColorPickerInput from '@components/input/HexInputColorPickerInput';
import Toolbar from '@components/form/Toolbar';
import InputGuesser from '@components/form/InputGuesser';
import PreviewField from '@components/resource/status/field/PreviewField';
import ReferenceManyGrid from '@components/field/ReferenceManyGrid';
import CheckboxInput from '@components/input/CheckboxInput';
import PassthroughProps from '@components/PassthroughProps';

import useFormFields from '@js/hooks/useFormFields';
import { Status } from '@js/interfaces/status';
import { useHydraSchemaContext } from '@js/context/HydraSchemaContext';

const HIDE_PARENT_FIELDS = ['parent', 'children', 'userGroups'];

const ParentForm = (props: Omit<TabbedFormProps, 'children' | 'onSubmit'>) => {
    const fields = useFormFields(props);
    const parentFields = fields.filter((field) => ['label', 'userGroups'].includes(field.name));
    const getResourceLabel = useGetResourceLabel();

    return (
        <TabbedForm {...props} toolbar={<Toolbar />}>
            <FormTab label="summary">
                {parentFields.map((field) => (
                    <InputGuesser key={field.name} source={field.name} field={field} />
                ))}
            </FormTab>
            <FormTab label={getResourceLabel('statuses')} path="statuses">
                <ReferenceManyGrid<Status, Status>
                    record={props.record}
                    resource={props.resource}
                    reference="statuses"
                    target="parent"
                    initialValues={(record) => ({ parent: record.id.toString() })}
                    sort={{ field: 'label', order: 'ASC' }}
                    hideFields={HIDE_PARENT_FIELDS}
                >
                    <PreviewField source="label" size="small" />
                </ReferenceManyGrid>
            </FormTab>
        </TabbedForm>
    );
};

const ChildForm = (props: Omit<SimpleFormProps, 'children' | 'onSubmit'>) => {
    const fields = useFormFields(props, HIDE_PARENT_FIELDS);
    const { schemaAnalyzer } = useHydraSchemaContext();
    const colorPickerFields = ['backgroundColor', 'textColor'];

    const fieldGroups = fields.reduce<{ colorPickerFields: Field[]; checkboxes: Field[]; inputs: Field[] }>(
        (acc, field) => {
            if (colorPickerFields.includes(field.name)) {
                return { ...acc, colorPickerFields: [...acc.colorPickerFields, field] };
            }

            const type = schemaAnalyzer.getFieldType(field);
            if (type === 'boolean') {
                return { ...acc, checkboxes: [...acc.checkboxes, field] };
            }

            return { ...acc, inputs: [...acc.inputs, field] };
        },
        {
            colorPickerFields: [],
            checkboxes: [],
            inputs: [],
        },
    );

    return (
        <SimpleForm toolbar={<Toolbar />} {...props}>
            {fieldGroups.inputs.map((field) => (
                <InputGuesser key={field.name} source={field.name} field={field} />
            ))}
            {fieldGroups.colorPickerFields.map((field) => (
                <HexInputColorPickerInput key={field.name} source={field.name} validate={[required()]} />
            ))}
            <PassthroughProps fullWidth>
                {() => (
                    <Box display="flex">
                        {chunk(fieldGroups.checkboxes, 4).map((fields) => (
                            <FormControl component="fieldset" key={fields.map((f) => f.name).join('')}>
                                <FormGroup>
                                    {fields.map((field) => (
                                        <CheckboxInput key={field.name} source={field.name} />
                                    ))}
                                </FormGroup>
                            </FormControl>
                        ))}
                    </Box>
                )}
            </PassthroughProps>
        </SimpleForm>
    );
};

const StatusForm: FC<Omit<SimpleFormProps, 'children' | 'onSubmit'>> = (props) => {
    const record = useRecordContext<Status>(props);
    const isChild = !!record?.parent;

    return <>{isChild ? <ChildForm {...props} /> : <ParentForm {...props} />}</>;
};

export default StatusForm;
