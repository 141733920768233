import PaymentIcon from '@material-ui/icons/Payment';

import SelloPaymentMethodEdit from './SelloPaymentMethodEdit';

import { RegisterResource } from '@components/ResourceGuesser';
import { SelloPaymentMethod } from '@js/interfaces/SelloPaymentMethod';

export default {
    create: null,
    edit: SelloPaymentMethodEdit,
    show: null,
    icon: PaymentIcon,
} satisfies RegisterResource<SelloPaymentMethod>;
