import { FC, MouseEvent, useState } from 'react';
import { Link } from 'react-router-dom';
import {
    Button,
    CreateButton,
    FilterButton,
    ListActionsProps,
    TopToolbar,
    useGetResourceLabel,
    useListContext,
    useTranslate,
} from 'react-admin';
import PublishIcon from '@material-ui/icons/Publish';
import { Menu, MenuItem } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';

import ErrandsConfigurableExportButton from '@components/button/ErrandsConfigurableExportButton';
import VisibleColumnsButton from '@components/list/VisibleColumnsButton';
import { useResourcePermissions } from '@js/context/UserPermissionsContext';
import { useIsFeatureDisabled } from '@js/context/AppConfigContext';
import useListFiltersExistsResetFix from '@js/hooks/useListFiltersExistsResetFix';

const StyledTopToolbar = withStyles({
    root: {
        flexWrap: 'wrap',
    },
})(TopToolbar);

const ErrandListActions: FC<ListActionsProps> = (props) => {
    const { isListActionEnabled } = useResourcePermissions(props);

    // Put it here because hook needs access to FilterContext which is wrapped around ListToolbar
    useListFiltersExistsResetFix();

    const importButtons = {
        errands: isListActionEnabled('import'),
        orders: isListActionEnabled('import_orders'),
        co2: isListActionEnabled('import_co2'),
    };

    return (
        <StyledTopToolbar>
            <>
                <VisibleColumnsButton {...props} />
                {isListActionEnabled('add_filter') && <FilterButton />}
                {isListActionEnabled('create') && <CreateButton />}
                {Object.values(importButtons).every(Boolean) && <MenuImportButton {...importButtons} />}
                {isListActionEnabled('export') && <ErrandsConfigurableExportButton />}
            </>
        </StyledTopToolbar>
    );
};

const MenuImportButton = ({ errands, orders, co2 }: { errands: boolean; orders: boolean; co2: boolean }) => {
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const translate = useTranslate();
    const { basePath } = useListContext();
    const getResourceLabel = useGetResourceLabel();
    const isFeatureDisabled = useIsFeatureDisabled();

    const handleClick = (event: MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    const getLocation = (path: string) => ({
        pathname: `${basePath}/${path}`,
        state: { _scrollToTop: true },
    });

    return (
        <>
            <Button label={translate('app.action.import')} onClick={handleClick}>
                <PublishIcon />
            </Button>
            <Menu id="simple-menu" anchorEl={anchorEl} keepMounted open={Boolean(anchorEl)} onClose={handleClose}>
                {errands && (
                    <MenuItem component={Link} to={getLocation('import')}>
                        {getResourceLabel('reclamations')}
                    </MenuItem>
                )}
                {orders && !isFeatureDisabled('ExternalOrders') && (
                    <MenuItem component={Link} to={getLocation('orders_import')}>
                        {translate('app.label.orders')}
                    </MenuItem>
                )}
                {co2 && (
                    <MenuItem component={Link} to={getLocation('import_co2')}>
                        {translate('app.action.import_co2')}
                    </MenuItem>
                )}
            </Menu>
        </>
    );
};

export default ErrandListActions;
