import { ReactElement } from 'react';
import { DatagridProps } from 'react-admin';
import { get } from 'lodash';

import SmallDatagrid from '@components/list/SmallDatagrid';
import TextSecondary from '@components/mui/TextSecondary';

import { Reclamation } from '@js/interfaces/reclamation';

const ReclamationSmallDatagrid = (props: DatagridProps & { rowActions: ReactElement }) => {
    return (
        <SmallDatagrid<Reclamation> {...props}>
            {(record, { fieldName }) => {
                return (
                    <>
                        <div>{get(record, fieldName)}</div>
                        <TextSecondary>{record.composedIdentifier}</TextSecondary>
                    </>
                );
            }}
        </SmallDatagrid>
    );
};

export default ReclamationSmallDatagrid;
