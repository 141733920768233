import { useState } from 'react';
import {
    Button,
    LinearProgress,
    RecordContextProvider,
    ReferenceInput,
    required,
    SelectInput,
    useGetResourceLabel,
    useNotify,
    useRecordContext,
    useReference,
    useResourceContext,
    useTranslate,
    useUpdate,
} from 'react-admin';
import get from 'lodash/get';
import { Dialog, DialogActions, DialogContent, DialogTitle } from '@material-ui/core';
import { Form } from 'react-final-form';

import CancelIcon from '@material-ui/icons/Cancel';
import SaveIcon from '@material-ui/icons/Save';

import AnchorText from '@components/field/AnchorText';
import LoadingButton from '@components/button/LoadingButton';

import useIsSmallScreen from '@js/hooks/useIsSmallScreen';
import useNotifyHttpError from '@js/hooks/useNotifyHttpError';
import useResourceFieldName from '@js/hooks/useResourceFieldName';

import { SelloSale } from '@js/interfaces/selloSale';

const SelloStatusField = ({
    source,
    ...props
}: {
    source: string;
    label?: string;
    resource?: string;
    record?: SelloSale;
    sortable?: boolean;
}) => {
    const [open, setOpen] = useState(false);

    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);
    const reference = 'sello_statuses';

    return (
        <>
            <SelloStatusesText source={source} reference={reference} onClick={handleOpen} record={props.record} />
            {open && (
                <SelloStatusSelectDialog
                    source={source}
                    reference={reference}
                    onClose={handleClose}
                    record={props.record}
                    resource={props.resource}
                />
            )}
        </>
    );
};

const SelloStatusSelectDialog = ({
    onClose,
    reference,
    source,
    ...props
}: {
    source: string;
    reference: string;
    onClose: () => void;
    record?: SelloSale;
    resource?: string;
}) => {
    const [open, setOpen] = useState(true);
    const record = useRecordContext(props);
    const resource = useResourceContext(props);
    const getResourceLabel = useGetResourceLabel();
    const isMobile = useIsSmallScreen();

    const fieldName = useResourceFieldName(reference);

    const [update] = useUpdate(resource, record?.id);
    const notify = useNotify();
    const notifyError = useNotifyHttpError();

    const handleClose = () => setOpen(false);

    const handleSubmit = async (data: { statusId: string }) => {
        return update(
            { payload: { data } },
            {
                onSuccess: () => {
                    notify('ra.notification.updated', {
                        type: 'info',
                        messageArgs: { smart_count: 1 },
                        undoable: false,
                    });
                    handleClose();
                },
                onFailure: notifyError,
                mutationMode: 'pessimistic',
                returnPromise: true,
            },
        );
    };

    const initialValues = {
        [source]: get(record, source),
    };

    return (
        <Dialog
            open={open}
            onClose={handleClose}
            TransitionProps={{ onExited: onClose }}
            fullScreen={isMobile}
            fullWidth
        >
            <DialogTitle>{getResourceLabel(reference)}</DialogTitle>
            <RecordContextProvider value={initialValues}>
                <Form onSubmit={handleSubmit} initialValues={initialValues}>
                    {({ handleSubmit, pristine, submitting }) => (
                        <>
                            <DialogContent>
                                <ReferenceInput reference={reference} source={source} validate={required()} fullWidth>
                                    <SelectInput optionText={fieldName} />
                                </ReferenceInput>
                            </DialogContent>
                            <DialogActions>
                                <Button onClick={handleClose} label="ra.action.cancel" disabled={submitting}>
                                    <CancelIcon />
                                </Button>
                                <LoadingButton
                                    label="ra.action.save"
                                    loading={submitting}
                                    icon={<SaveIcon />}
                                    onClick={handleSubmit}
                                    disabled={pristine}
                                />
                            </DialogActions>
                        </>
                    )}
                </Form>
            </RecordContextProvider>
        </Dialog>
    );
};

const SelloStatusesText = ({
    source,
    reference,
    onClick,
    ...props
}: {
    source: string;
    reference: string;
    record?: SelloSale;
    onClick: () => void;
}) => {
    const record = useRecordContext(props);
    const fieldName = useResourceFieldName(reference);
    const getResourceLabel = useGetResourceLabel();
    const translate = useTranslate();

    const { loaded, referenceRecord } = useReference({ reference, id: get(record, source) });

    if (!loaded) return <LinearProgress />;

    return (
        <AnchorText onClick={onClick} noWrap>
            {referenceRecord
                ? get(referenceRecord, fieldName)
                : translate('app.action.set', { subject: getResourceLabel(reference) })}
        </AnchorText>
    );
};

export default SelloStatusField;
