import { useRecordContext } from 'react-admin';
import { Box, IconButton, Tooltip, Typography } from '@material-ui/core';
import { get } from 'lodash';
import InfoIcon from '@material-ui/icons/Info';

import { useFormatPrice } from '@js/hooks/useFormatPrice';
import { GiabsSale } from '@js/interfaces/GiabsSale';

type Props = {
    source: string;
    currencyField: string;
    record?: GiabsSale;
    resource?: string;
    sortable?: boolean;
};

const GiabsSalePriceField = ({ source, currencyField, ...props }: Props) => {
    const record = useRecordContext(props);
    const currency = get(record, currencyField!);

    const formatPrice = useFormatPrice(currency);
    const formattedSalesPrice = formatPrice(get(record, source!));

    if (!record) return null;

    const { serviceFeePrice, mobileCirclePrice, cost } = record;

    const saleCalculationFormula = `${formatPrice(serviceFeePrice)} + ${formatPrice(mobileCirclePrice)} - ${formatPrice(
        cost,
    )} = ${formattedSalesPrice}`;

    return (
        <Box display="flex" alignItems="center">
            <Typography component="span" variant="body2">
                {formattedSalesPrice}
            </Typography>
            <Tooltip title={saleCalculationFormula}>
                <IconButton size="small" color="secondary" onClick={(e) => e.preventDefault()}>
                    <InfoIcon fontSize="small" />
                </IconButton>
            </Tooltip>
        </Box>
    );
};

GiabsSalePriceField.defaultProps = {
    sortable: false,
};

export default GiabsSalePriceField;
