import { ReactNode } from 'react';
import { Typography } from '@material-ui/core';

const TextSecondary = ({ children }: { children?: ReactNode }) => {
    return (
        <Typography component="div" variant="body2" color="textSecondary">
            {children}
        </Typography>
    );
};

export default TextSecondary;
