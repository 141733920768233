import { useState } from 'react';
import { useNotify, useRecordContext, useResourceContext, useTranslate, useUpdate } from 'react-admin';
import { Button as MuiButton, Dialog, DialogActions, DialogContent, DialogTitle } from '@material-ui/core';
import { Form } from 'react-final-form';
import { Field } from '@api-platform/api-doc-parser';

import BorderColorIcon from '@material-ui/icons/BorderColor';
import CancelIcon from '@material-ui/icons/Cancel';
import SaveIcon from '@material-ui/icons/Save';

import IconButton from '@components/button/IconButton';
import LoadingButton from '@components/button/LoadingButton';
import ReclamationInputGuesser from '../input/ReclamationInputGuesser';

import useIsSmallScreen from '@js/hooks/useIsSmallScreen';
import useFormFields from '@js/hooks/useFormFields';
import useNotifyHttpError from '@js/hooks/useNotifyHttpError';

import { Reclamation } from '@js/interfaces/reclamation';

type Props = {
    record?: Reclamation;
    resource?: string;
};

const QuickEditButton = (props: Props) => {
    const [open, setOpen] = useState(false);

    const handleClick = () => {
        setOpen(true);
    };
    const handleClose = () => {
        setOpen(false);
    };

    return (
        <>
            <IconButton label="ra.action.edit" onClick={handleClick}>
                <BorderColorIcon />
            </IconButton>
            {open && <EditDialog onClose={handleClose} {...props} />}
        </>
    );
};

const EditDialog = ({ onClose, ...props }: { onClose: () => void; record?: Reclamation; resource?: string }) => {
    const [open, setOpen] = useState(true);
    const fullScreen = useIsSmallScreen();
    const translate = useTranslate();
    const record = useRecordContext(props);
    const resource = useResourceContext(props);
    const formFields = useFormFields(props);
    const [update] = useUpdate(resource, record?.id);
    const notify = useNotify();
    const notifyFailure = useNotifyHttpError();

    const editFields = (['location', 'images'] satisfies Array<keyof Reclamation>)
        .map((field) => formFields.find((formField) => formField.name === field))
        .filter(Boolean) as Field[];

    const handleClose = () => setOpen(false);

    const handleSubmit = async (data: Record<string, any>) => {
        return update(
            { payload: { data } },
            {
                onSuccess: () => {
                    notify('ra.notification.updated', {
                        type: 'info',
                        messageArgs: { smart_count: 1 },
                        undoable: false,
                    });
                    handleClose();
                },
                onFailure: notifyFailure,
                mutationMode: 'pessimistic',
                returnPromise: true,
            },
        );
    };

    return (
        <Dialog
            open={open}
            onClose={handleClose}
            TransitionProps={{ onExited: onClose }}
            fullScreen={fullScreen}
            maxWidth="lg"
            fullWidth
        >
            <DialogTitle>{translate('ra.action.edit')}</DialogTitle>
            <Form onSubmit={handleSubmit} initialValues={{ ...record }}>
                {(formProps) => (
                    <>
                        <DialogContent>
                            {editFields.map((field) => {
                                return <ReclamationInputGuesser field={field} source={field.name} key={field.name} />;
                            })}
                        </DialogContent>
                        <DialogActions>
                            <MuiButton startIcon={<CancelIcon />} onClick={onClose} size="small">
                                {translate('ra.action.cancel')}
                            </MuiButton>
                            <LoadingButton
                                disabled={formProps.pristine}
                                loading={formProps.submitting}
                                onClick={formProps.handleSubmit}
                                label="ra.action.save"
                                icon={<SaveIcon />}
                            />
                        </DialogActions>
                    </>
                )}
            </Form>
        </Dialog>
    );
};

export default QuickEditButton;
