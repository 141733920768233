import { ListProps, SearchInput } from 'react-admin';

import ListGuesser, { DatagridGuesser } from '@components/list/ListGuesser';
import SecondsTimeField from '@components/field/SecondsTimeField';
import AutoCompleteGuesserInput from '@components/input/AutoCompleteGuesserInput';

import useIsSmallScreen from '@js/hooks/useIsSmallScreen';
import SimpleListGuesser from '@components/list/SimpleListGuesser';
import RowActions from '@components/list/RowActions';

const filters = [
    <SearchInput key="q" source="q" alwaysOn />,
    <AutoCompleteGuesserInput key="userGroup" source="userGroup" alwaysOn multiple />,
];

const PredefinedTextList = (props: ListProps) => {
    const isSmall = useIsSmallScreen();

    return (
        <ListGuesser {...props} filters={filters}>
            {isSmall ? (
                <SimpleListGuesser hasDelete />
            ) : (
                <DatagridGuesser rowActions={<RowActions hasDelete />}>
                    <SecondsTimeField source="time" />
                </DatagridGuesser>
            )}
        </ListGuesser>
    );
};

export default PredefinedTextList;
