import { useState } from 'react';
import { useGetMany, useGetResourceLabel, useInput, useTranslate } from 'react-admin';
import {
    Button as MuiButton,
    Chip,
    Dialog as MuiDialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    TextField,
} from '@material-ui/core';
import { get } from 'lodash';
import { makeStyles } from '@material-ui/core/styles';
import CancelIcon from '@material-ui/icons/Cancel';

import CategoryTreeInput from '@components/input/CategoryTreeInput';

import useInputReferenceField from '@js/hooks/useInputReferenceField';
import useIsSmallScreen from '@js/hooks/useIsSmallScreen';
import useTranslateResourceField from '@js/hooks/useTranslateResourceField';

type Props = {
    source: string;
    resource?: string;
    multiple?: boolean;
    alwaysOn?: boolean;
};

const useStyles = makeStyles((theme) => ({
    chips: {
        display: 'flex',
        flexWrap: 'wrap',
        padding: theme.spacing(1),
        minHeight: theme.spacing(3),
    },
    chip: {
        margin: theme.spacing(1 / 4),
    },
    input: {
        minWidth: theme.spacing(40),
        marginBottom: theme.spacing(1),
    },
}));

const CategoryDialogInput = (props: Props) => {
    const [open, setOpen] = useState(false);
    const classes = useStyles();

    const { reference, fieldName, multiple } = useInputReferenceField(props);
    const { input } = useInput(props);
    const value = Array.isArray(input.value) ? input.value : [];

    const getFieldLabel = useTranslateResourceField(props.resource);
    const { data: categories } = useGetMany(reference, value, { enabled: value.length > 0 });

    return (
        <>
            <TextField
                label={getFieldLabel(props.source)}
                variant="filled"
                className={classes.input}
                onClick={() => setOpen(true)}
                InputProps={{
                    inputComponent: () => {
                        return (
                            <div className={classes.chips}>
                                {categories.map((category) => (
                                    <Chip
                                        key={category?.id}
                                        label={get(category, fieldName)}
                                        className={classes.chip}
                                        onDelete={() => {
                                            if (multiple) {
                                                input.onChange(value.filter((v) => v !== category.id));
                                            } else {
                                                input.onChange(null);
                                            }
                                        }}
                                    />
                                ))}
                            </div>
                        );
                    },
                }}
            />
            {open && (
                <CategoryDialog
                    source={props.source}
                    reference={reference}
                    multiple={multiple}
                    onClose={() => setOpen(false)}
                />
            )}
        </>
    );
};

const CategoryDialog = ({
    source,
    reference,
    multiple,
    onClose,
}: {
    source: string;
    multiple: boolean;
    reference: string;
    onClose: () => void;
}) => {
    const [open, setOpen] = useState(true);
    const fullScreen = useIsSmallScreen();
    const translate = useTranslate();
    const getResourceLabel = useGetResourceLabel();

    const handleClose = () => setOpen(false);

    return (
        <MuiDialog
            open={open}
            fullScreen={fullScreen}
            maxWidth="md"
            onClose={handleClose}
            TransitionProps={{ onExited: onClose }}
            fullWidth
        >
            <DialogTitle>{getResourceLabel(reference)}</DialogTitle>
            <DialogContent>
                <CategoryTreeInput source={source} multiple={multiple} fullWidth />
            </DialogContent>
            <DialogActions>
                <MuiButton startIcon={<CancelIcon />} onClick={onClose} size="small">
                    {translate('ra.action.cancel')}
                </MuiButton>
            </DialogActions>
        </MuiDialog>
    );
};

export default CategoryDialogInput;
