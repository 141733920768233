import { ReactElement, ReactNode } from 'react';
import { Datagrid, DatagridProps } from 'react-admin';
import { makeStyles } from '@material-ui/core/styles';
import { TableContainer } from '@material-ui/core';

import FunctionField, { FunctionFieldProps } from '@components/field/FunctionField';

import useResourceFieldName from '@js/hooks/useResourceFieldName';

import { ApiRecord } from '@js/interfaces/ApiRecord';

const useStyles = makeStyles({
    cell: {
        paddingLeft: 0,
        paddingRight: 0,
    },
});

const SmallDatagrid = <RecordType extends ApiRecord>({
    rowActions,
    children,
    ...props
}: DatagridProps & {
    rowActions?: ReactElement;
    children: (
        record: RecordType,
        props: Omit<FunctionFieldProps<RecordType>, 'render'> & { fieldName: string },
    ) => ReactNode;
}) => {
    const classes = useStyles();
    const fieldName = useResourceFieldName(props);

    return (
        <TableContainer>
            <Datagrid {...props}>
                <FunctionField<RecordType>
                    label={false}
                    sortable={false}
                    cellClassName={classes.cell}
                    source={fieldName}
                    render={(record, props) => children(record, { ...props, fieldName })}
                />
                {rowActions}
            </Datagrid>
        </TableContainer>
    );
};

export default SmallDatagrid;
