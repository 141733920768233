/* eslint-disable react/jsx-key */
import { ListProps, SearchInput, useRecordContext, useTranslate } from 'react-admin';
import { get } from 'lodash';

import ListGuesser, { DatagridGuesser } from '@components/list/ListGuesser';
import RowActions from '@components/list/RowActions';
import SimpleListGuesser from '@components/list/SimpleListGuesser';
import CategoryDialogInput from '@components/input/CategoryDialogInput';

import useIsSmallScreen from '@js/hooks/useIsSmallScreen';
import { ProcessingTimeDefaults } from '@js/interfaces/ProcessingTimeDefaults';

const ProcessingTimeDefaultsList = (props: ListProps) => {
    const isSmall = useIsSmallScreen();
    return (
        <ListGuesser {...props} filters={filters}>
            {isSmall ? (
                <SimpleListGuesser<ProcessingTimeDefaults>
                    hasDelete
                    primaryText={(record) => <TimeInMinutesField source="timeInMinutes" record={record} />}
                />
            ) : (
                <DatagridGuesser rowActions={<RowActions hasDelete />}>
                    <TimeInMinutesField source="timeInMinutes" />
                </DatagridGuesser>
            )}
        </ListGuesser>
    );
};

const TimeInMinutesField = (props: { source: string; record?: ProcessingTimeDefaults }) => {
    const record = useRecordContext(props);
    const translate = useTranslate();

    if (!record) return null;

    const value = get(record, props.source) ?? 0;
    return <span>{translate('app.label.minutes', { minutes: value, smart_count: value })}</span>;
};

const filters = [
    <SearchInput source="q" alwaysOn />,
    <CategoryDialogInput source="firstTimeAssignedCategories" alwaysOn multiple />,
];

export default ProcessingTimeDefaultsList;
