import { FC } from 'react';
import {
    Datagrid as RaDatagrid,
    DatagridProps,
    Record as RaRecord,
    ReferenceManyField,
    ReferenceManyFieldProps,
    TopToolbar,
    useRecordContext,
    useTranslate,
} from 'react-admin';
import { Alert } from '@material-ui/lab';

import { ListGuesserBase, useListGuesserControllerContext } from '@components/list/ListGuesser';
import DatagridBodyWithMercure from '@components/list/DatagridBodyWithMercure';
import AlertEmptyResource from '@components/list/AlertEmptyResource';
import SimpleListGuesser from '@components/list/SimpleListGuesser';
import RowActions from '@components/list/RowActions';
import AutoHidePagination from '@components/list/AutoHidePagination';
import CreateSubResourceButton from '@components/button/CreateSubResourceButton';
import VisibleColumnsButton from '@components/list/VisibleColumnsButton';
import ErrandFieldGuesser from '@components/resource/errand/field/ErrandFieldGuesser';

import useIsSmallScreen from '@js/hooks/useIsSmallScreen';
import { isFieldSortable } from '@js/utility/fieldsUtil';
import useResourceSchema from '@js/hooks/useResourceSchema';

import { Errand } from '@js/interfaces/errand';

const Datagrid: FC<DatagridProps> = (props) => {
    const schema = useResourceSchema(props);
    const { selectedFields } = useListGuesserControllerContext();

    return (
        <>
            <TopToolbar>
                <VisibleColumnsButton />
            </TopToolbar>
            <RaDatagrid {...props} body={DatagridBodyWithMercure} empty={<AlertEmptyResource />}>
                {selectedFields.map((field) => (
                    <ErrandFieldGuesser
                        key={field.name}
                        source={field.name}
                        field={field}
                        sortable={isFieldSortable(field, schema)}
                    />
                ))}
                <RowActions subResourceActions hasDelete />
            </RaDatagrid>
        </>
    );
};

type Props = {
    record?: RaRecord;
    initialValues: (record: RaRecord) => Partial<Errand>;
} & Omit<ReferenceManyFieldProps, 'record' | 'reference' | 'children'>;

const ErrandsDataGrid = ({ initialValues, ...props }: Props) => {
    const record = useRecordContext(props);
    const translate = useTranslate();
    const isSmall = useIsSmallScreen();

    if (!record?.id) {
        return <Alert severity="info">{translate('app.label.first_save')}</Alert>;
    }

    return (
        <>
            <ReferenceManyField
                addLabel={false}
                pagination={<AutoHidePagination />}
                reference="errands"
                sort={{ field: 'createdAt', order: 'desc' }}
                {...props}
            >
                <ListGuesserBase resource="errands">
                    {isSmall ? (
                        <SimpleListGuesser<Errand>
                            secondaryText={(record) => record.composedIdentifier}
                            subResourceActions
                        />
                    ) : (
                        <Datagrid />
                    )}
                </ListGuesserBase>
            </ReferenceManyField>
            <CreateSubResourceButton<Errand> initialValues={initialValues(record)} resource="errands" />
        </>
    );
};

export default ErrandsDataGrid;
