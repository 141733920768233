import { useNotify, useRecordContext, useUpdate } from 'react-admin';

import ReclamationInputGuesser from '../../input/ReclamationInputGuesser';

import useNotifyHttpError from '@js/hooks/useNotifyHttpError';
import ChatGPTManagerButton from '@components/button/ChatGPTManagerButton';
import useDisableReason from './useDisableReason';

import { Reclamation } from '@js/interfaces/reclamation';
import { CompletionField } from './common';

const ChatGPTRegularButton = (props: { resource?: string; record?: Reclamation }) => {
    const record = useRecordContext(props);
    const [update] = useUpdate('reclamations', record?.id);
    const notify = useNotify();
    const notifyFailure = useNotifyHttpError();

    const disabledReason = useDisableReason(record ?? {});
    const fields: CompletionField[] = ['insurancePrice', 'shortDescription', 'longDescription', 'weight', 'measure'];

    const handleCompletion = async (data: Record<CompletionField, string>) => {
        await update(
            { payload: { data } },
            {
                onSuccess: () => {
                    notify('ra.notification.updated', {
                        type: 'info',
                        messageArgs: { smart_count: 1 },
                        undoable: false,
                    });
                },
                onFailure: notifyFailure,
                mutationMode: 'pessimistic',
                returnPromise: true,
            },
        );
    };

    return (
        <ChatGPTManagerButton
            createGetCompletionPayload={(completionFields) => ({ completionFields, reclamation: record?.id })}
            completionFields={fields}
            onCompletion={handleCompletion}
            disabledReason={disabledReason}
            inputComponent={ReclamationInputGuesser}
        />
    );
};

export default ChatGPTRegularButton;
