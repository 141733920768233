import { ReactNode } from 'react';
import {
    Datagrid,
    DateField,
    ReferenceField,
    ReferenceManyField,
    ReferenceManyFieldProps,
    SimpleList,
    SimpleListProps,
    useLocale,
    useRecordContext,
    useTranslate,
} from 'react-admin';
import { colors as muiColors, Typography } from '@material-ui/core';

import AutoHidePagination from '@components/list/AutoHidePagination';
import PassthroughProps from '@components/PassthroughProps';
import AlertEmptyResource from '@components/list/AlertEmptyResource';
import ReferenceFieldGuesser from '@components/field/ReferenceFieldGuesser';
import FunctionField from '@components/field/FunctionField';

import ExpandRow, { isRowExpandable } from './ExpandRow';
import RowActions from './RowActions';
import StatusField from './field/StatusField';
import TypeField from './field/TypeField';
import QuantityField from './field/QuantityField';
import SalesPriceField from './field/SalesPriceField';
import AdditionalInfoField from './field/AdditionalInfoField';

import useIsSmallScreen from '@js/hooks/useIsSmallScreen';
import { useFormatPrice } from '@js/hooks/useFormatPrice';

import { Sale, Status } from '@js/interfaces/Sale';
import { SaleChannel } from '@js/interfaces/SaleChannel';

type Props = Omit<ReferenceManyFieldProps, 'sort' | 'target' | 'reference' | 'children'> & {
    filterWarning?: ReactNode;
    target?: string;
};

const SalesReferenceDatagrid = ({ filterWarning, ...props }: Props) => {
    const isSmall = useIsSmallScreen();

    return (
        <ReferenceManyField
            perPage={10}
            pagination={<AutoHidePagination />}
            target="reclamation"
            {...props}
            sort={{ field: 'soldAt', order: 'desc' }}
            reference="sales"
        >
            <PassthroughProps<{ record?: Sale }>>
                {(props) => {
                    return (
                        <>
                            {filterWarning}
                            {isSmall ? (
                                <SalesSimpleList {...props} />
                            ) : (
                                <Datagrid
                                    {...props}
                                    empty={<AlertEmptyResource mb={2} />}
                                    isRowExpandable={isRowExpandable}
                                    expand={<ExpandRow />}
                                    rowStyle={rowStyle}
                                >
                                    <DateField source="soldAt" showTime />
                                    <TypeField />
                                    <StatusField />
                                    <QuantityField />
                                    <SalesPriceField />
                                    <ReferenceFieldGuesser source="saleChannel" sortable={false} asText />
                                    <AdditionalInfoField />
                                    <RowActions />
                                </Datagrid>
                            )}
                        </>
                    );
                }}
            </PassthroughProps>
        </ReferenceManyField>
    );
};

export const SalesSimpleList = (props: SimpleListProps<Sale> & { record?: Sale }) => {
    const formatPrice = useFormatPrice();
    const translate = useTranslate();
    const locale = useLocale();

    return (
        <SimpleList
            {...props}
            linkType={false}
            rowStyle={rowStyle}
            primaryText={(record) => {
                return formatPrice(record.salesPrice);
            }}
            secondaryText={(record) => {
                return (
                    <span>
                        {translate(`resources.sales.status.${record.status}`)}
                        <br />
                        {new Date(record.soldAt).toLocaleString(locale)}
                    </span>
                );
            }}
            tertiaryText={(record) => <SimpleSaleChannelField record={record} />}
        />
    );
};

const SimpleSaleChannelField = (props: { record: Sale }) => {
    const record = useRecordContext(props);
    const translate = useTranslate();

    return (
        <ReferenceField record={record} source="saleChannel" reference="sale_channels" linkType={false}>
            <FunctionField<SaleChannel>
                render={(saleChannel) => (
                    <Typography variant="body2" component="span">
                        {saleChannel?.name} ({translate(`resources.sales.type.${record.type}`)})
                    </Typography>
                )}
            />
        </ReferenceField>
    );
};

export const rowStyle = (record: any) => {
    const status = (record as Sale | undefined)?.status;
    const mapping: { [key in Status]?: string } = {
        canceled: muiColors.deepOrange[50],
        pending: muiColors.yellow[50],
        deleted: muiColors.red[100],
    };

    if (status && status in mapping) {
        return { backgroundColor: mapping[status] };
    }
};

export default SalesReferenceDatagrid;
