import { ComponentType, FC } from 'react';
import {
    DeleteButton,
    EditButton,
    ShowButton as ShowButtonComponent,
    useResourceContext,
    useResourceDefinition,
} from 'react-admin';
import { Box, makeStyles, MenuItem } from '@material-ui/core';

import EditButtonReturnUrl from '@components/button/EditButtonReturnUrl';
import MenuButton from '@components/button/MenuButton';
import LockRecordIcon from '@components/detail/LockRecordIcon';

import useIsSmallScreen from '@js/hooks/useIsSmallScreen';
import { ApiRecord } from '@js/interfaces/ApiRecord';
import { useUserPermissions } from '@js/context/UserPermissionsContext';

interface Props {
    record?: ApiRecord;
    resource?: string;
    hasShow?: boolean;
    hasEdit?: boolean;
    hasDelete?: boolean;
    forceShowDelete?: boolean;
    showButton?: ComponentType;
    editButton?: ComponentType;
    deleteButton?: ComponentType;
    subResourceActions?: boolean;
    basePath?: string;
    showLockIcon?: boolean;
}

const RowActions: FC<Props> = ({
    hasDelete,
    forceShowDelete,
    editButton,
    deleteButton,
    subResourceActions,
    showLockIcon,
    showButton: ShowButton = ShowButtonComponent,
    ...props
}) => {
    const { hasShow, hasEdit } = useResourceDefinition(props);
    const isSmall = useIsSmallScreen();
    const resource = useResourceContext(props);
    const permissions = useUserPermissions();
    const classes = useStyles();

    // Allow deleting only if editing is allowed
    hasDelete = forceShowDelete || (hasDelete && permissions.isEditActionEnabled(resource, 'delete'));

    const buttons = [];

    if (hasShow) {
        buttons.push(<ShowButton key="show" {...sanitizeButtonProps(props)} />);
    }

    if (hasEdit) {
        const Component = editButton || (subResourceActions ? EditButtonReturnUrl : EditButton);
        buttons.push(<Component key="edit" {...sanitizeButtonProps(props)} />);
    }

    if (hasDelete) {
        const Component = deleteButton || DeleteButton;

        buttons.push(
            <Component
                key="delete"
                {...sanitizeButtonProps(props)}
                mutationMode="pessimistic"
                redirect={subResourceActions ? false : undefined}
            />,
        );
    }

    return (
        <Box display="flex" justifyContent="end">
            {showLockIcon && <LockRecordIcon />}
            {isSmall ? (
                <MenuButton>
                    {buttons.map((button) => {
                        return (
                            <MenuItem key={button.key} className={classes.menuItem} disableGutters>
                                {button}
                            </MenuItem>
                        );
                    })}
                </MenuButton>
            ) : (
                <>{buttons}</>
            )}
        </Box>
    );
};

const useStyles = makeStyles((theme) => ({
    menuItem: {
        '& > .MuiIconButton-root': {
            padding: theme.spacing(1),
        },
    },
}));

const sanitizeButtonProps = ({ basePath, record }: { basePath?: string; record?: ApiRecord }) => ({ basePath, record });

RowActions.defaultProps = {
    subResourceActions: false,
};

export default RowActions;
