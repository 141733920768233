/* eslint-disable react/jsx-key */
import { useMemo } from 'react';
import { SearchInput, SelectArrayInput } from 'react-admin';

import DateRangeFilter from '@components/filter/DateRangeFilter';
import AutoCompleteGuesserInput from '@components/input/AutoCompleteGuesserInput';

import { Sale, Status } from '@js/interfaces/Sale';

const useListFilters = () => {
    return useMemo(
        () => [
            <SearchInput source="q" alwaysOn autoComplete="off" />,
            <DateRangeFilter source="soldAt" alwaysOn />,
            <SelectArrayInput
                source="type"
                choices={(
                    [
                        'sello',
                        'giab_s',
                        'external',
                        'manual',
                        'purchase_separately',
                        'imported',
                    ] satisfies Sale['type'][]
                ).map((type) => ({
                    id: type,
                    name: `resources.sales.type.${type}`,
                }))}
                alwaysOn
                style={{ minWidth: 120 }}
            />,
            <SelectArrayInput
                source="status"
                choices={(['completed', 'pending', 'canceled', 'deleted'] satisfies Status[]).map((type) => ({
                    id: type,
                    name: `resources.sales.status.${type}`,
                }))}
                alwaysOn
                style={{ minWidth: 120 }}
            />,
            <AutoCompleteGuesserInput source="saleChannel" alwaysOn multiple />,
        ],
        [],
    );
};

export default useListFilters;
