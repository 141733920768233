import { createPortal } from 'react-dom';
import { FC, ReactElement, useCallback } from 'react';
import {
    DatagridProps,
    FilterButton as RaFilterButton,
    FilterContext,
    FilterForm as RaFilterForm,
    ReferenceManyField,
    ReferenceManyFieldProps,
    useListContext,
} from 'react-admin';
import debounce from 'lodash/debounce';

import AlertEmptyResource from '@components/list/AlertEmptyResource';
import AutoHidePagination from '@components/list/AutoHidePagination';
import { ListGuesserBase } from '@components/list/ListGuesser';
import DatagridBodyWithMercure from '@components/list/DatagridBodyWithMercure';
import ReclamationSmallDatagrid from '@components/resource/reclamation/ReclamationSmallDatagrid';

import DefaultDatagrid from './DefaultDatagrid';
import ExpandShowPanel from './ExpandShowPanel';
import ViewRowActions from './ViewRowActions';
import Toolbar from './Toolbar';

import useIsSmallScreen from '@js/hooks/useIsSmallScreen';
import filters from './filters';

type CommonProps = {
    editMode?: boolean;
    rowActions?: ReactElement;
    expand?: ReactElement;
};

export const hideFields = ['statusNotifications'];

const FilterButton = () => {
    const container = document.getElementById('errand-filter-button');
    if (!container) {
        return null;
    }

    return createPortal(<RaFilterButton />, container);
};

const FilterForm = () => {
    const container = document.getElementById('errand-filter-form');
    const { setFilters } = useListContext();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    const debounceSetFilters = useCallback(
        debounce((filters: any, displayedFilters: any) => setFilters(filters, displayedFilters), 500),
        [setFilters],
    );

    if (!container) {
        return null;
    }

    return createPortal(<RaFilterForm setFilters={debounceSetFilters} />, container);
};

const ListView: FC<DatagridProps & CommonProps> = ({
    editMode,
    rowActions = <ViewRowActions />,
    expand = <ExpandShowPanel hideFields={hideFields} />,
    ...props
}) => {
    const isSmall = useIsSmallScreen();

    const commonDatagridProps: DatagridProps = {
        expand,
        body: DatagridBodyWithMercure,
        empty: <AlertEmptyResource />,
    };

    return (
        <ListGuesserBase resource={props.resource} hideFields={hideFields}>
            {editMode && (
                <FilterContext.Provider value={filters}>
                    <FilterForm />
                    <FilterButton />
                </FilterContext.Provider>
            )}
            {isSmall ? (
                <>
                    <Toolbar />
                    <ReclamationSmallDatagrid {...props} {...commonDatagridProps} rowActions={rowActions} />
                </>
            ) : (
                <DefaultDatagrid {...props} {...commonDatagridProps} rowActions={rowActions} />
            )}
        </ListGuesserBase>
    );
};

const ReclamationEditableDatagrid: FC<
    Omit<ReferenceManyFieldProps, 'children' | 'target' | 'reference'> & CommonProps
> = ({ editMode, rowActions, ...props }) => {
    return (
        <ReferenceManyField
            reference="reclamations"
            target="errand"
            addLabel={false}
            pagination={<AutoHidePagination />}
            perPage={10}
            sort={{ field: 'id', order: 'desc' }}
            {...props}
        >
            <ListView editMode={editMode} rowActions={rowActions} />
        </ReferenceManyField>
    );
};

export default ReclamationEditableDatagrid;
