import { useNotify, useRecordContext } from 'react-admin';
import { forwardRef, ForwardRefRenderFunction, useImperativeHandle, useState } from 'react';
import { useForm, useFormState } from 'react-final-form';

import ChatGPTManagerButton from '@components/button/ChatGPTManagerButton';
import ReclamationInputGuesser from '../../input/ReclamationInputGuesser';

import useDisableReason from './useDisableReason';
import { CompletionField, COMPLETION_FIELDS } from './common';

const ChatGPTFormButton: ForwardRefRenderFunction<{ open: () => void; close: () => void }> = (_, ref) => {
    const [open, setOpen] = useState(false);

    const record = useRecordContext();
    const form = useForm();
    const { values } = useFormState({ subscription: { values: true } });
    const { title, ean } = values ?? {};

    const notify = useNotify();
    const disabledReason = useDisableReason({ title, ean });

    const handleOpen = () => {
        if (disabledReason) return;
        setOpen(true);
    };
    const handleClose = () => setOpen(false);

    useImperativeHandle(ref, () => ({
        open: handleOpen,
        close: handleClose,
    }));

    const handleCompletion = async (data: Record<CompletionField, string>) => {
        form.batch(() => {
            for (const [field, value] of Object.entries(data)) {
                form.change(field, value);
            }
        });
        notify('ra.notification.updated', {
            type: 'info',
            messageArgs: { smart_count: 1 },
        });
    };

    return (
        <ChatGPTManagerButton
            open={open}
            onOpenChange={(open) => (open ? handleOpen() : handleClose())}
            createGetCompletionPayload={(completionFields) => ({
                title,
                ean,
                completionFields,
                reclamation: record?.id,
            })}
            completionFields={COMPLETION_FIELDS}
            onCompletion={handleCompletion}
            disabledReason={disabledReason}
            inputComponent={ReclamationInputGuesser}
        />
    );
};

export default forwardRef(ChatGPTFormButton);
