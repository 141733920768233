import { FC, ReactNode } from 'react';
import { SimpleForm, SimpleFormProps } from 'react-admin';

import InputGuesser from '@components/form/InputGuesser';
import Toolbar from '@components/form/Toolbar';
import useFormFields from '@js/hooks/useFormFields';
import { getOverrideFields } from '@js/utility/fieldsUtil';

type Props = SafeOmit<SimpleFormProps, 'children' | 'onSubmit'> & {
    children?: ReactNode;
    hideInputs?: string[];
    fullWidth?: boolean;
};

const SimpleFormGuesser: FC<Props> = ({ hideInputs, children, fullWidth, ...props }) => {
    const fields = useFormFields(props, hideInputs);
    const overrideFields = getOverrideFields(children);

    return (
        <SimpleForm toolbar={<Toolbar />} {...props}>
            {fields.map((field) => {
                return (
                    overrideFields[field.name] ?? (
                        <InputGuesser key={field.name} source={field.name} field={field} fullWidth={fullWidth} />
                    )
                );
            })}
        </SimpleForm>
    );
};

export default SimpleFormGuesser;
