import { useRef } from 'react';
import { InjectedFieldProps, useRecordContext } from 'react-admin';
import { Box, MenuItem } from '@material-ui/core';

import PrintItemLabelButton from '@components/button/PrintItemLabelButton';
import LockRecordIcon from '@components/detail/LockRecordIcon';
import SyncSelloButton from '@components/button/SyncSelloButton';
import DeleteIconButton from '@components/button/DeleteIconButton';
import ShowIconButton from '@components/button/ShowIconButton';
import EditIconButton from '@components/button/EditIconButton';
import MenuButton, { MenuHandle } from '@components/button/MenuButton';

import { Product } from '@js/interfaces/product';

type Props = InjectedFieldProps<Product>;

const RowActions = (props: Props) => {
    const record = useRecordContext(props);
    const menuRef = useRef<MenuHandle>(null);
    const handleClose = () => menuRef.current?.close();

    if (!record) return null;

    return (
        <Box display="flex" justifyContent="end">
            <LockRecordIcon />
            <MenuButton ref={menuRef} keepMounted>
                <MenuItem onClick={handleClose}>
                    <EditIconButton {...props} />
                </MenuItem>
                <MenuItem onClick={handleClose}>
                    <SyncSelloButton {...props} />
                </MenuItem>
                <MenuItem onClick={handleClose}>
                    <PrintItemLabelButton {...props} />
                </MenuItem>
                <MenuItem onClick={handleClose}>
                    <ShowIconButton {...props} />
                </MenuItem>
                <MenuItem onClick={handleClose}>
                    <DeleteIconButton
                        confirmContent={
                            record.syncedWithSello ? 'resources.products.message.was_synced_with_sello' : undefined
                        }
                        disabledReason={record.deleteDisableReason}
                    />
                </MenuItem>
            </MenuButton>
        </Box>
    );
};

export default RowActions;
