import {
    Datagrid,
    DateField,
    FunctionField,
    List,
    ListProps,
    NullableBooleanInput,
    NumberInput,
    Record,
    TextField,
    useGetResourceLabel,
} from 'react-admin';
import { Typography } from '@material-ui/core';
import { get } from 'lodash';

import RowActions from '@components/list/RowActions';
import SmallDatagrid from '@components/list/SmallDatagrid';

import CreateErrandFromInitialValuesButton from './CreateErrandFromInitialValuesButton';
import ErrandDraftExpandPanel from './ErrandDraftExpandPanel';

import { ErrandDraft } from '@js/interfaces/erranddraft';
import useIsSmallScreen from '@js/hooks/useIsSmallScreen';

const defaultSort = { field: 'createdAt', order: 'DESC' };

const rowStyle = (record: Record) => ({
    ...((record?.errands?.length ?? 0) <= 0 && { backgroundColor: '#efe' }),
});

const filters = [
    <NullableBooleanInput
        key="exists[errands]"
        source="exists[errands]"
        nullLabel="resources.errand_drafts.filter.errands.all"
        falseLabel="resources.errand_drafts.filter.errands.new"
        trueLabel="resources.errand_drafts.filter.errands.already_used"
        alwaysOn
        style={{ minWidth: '200px' }}
    />,
    <NumberInput key="id" source="id" />,
];

const ErrandDraftList = (props: ListProps) => {
    const translateResource = useGetResourceLabel();
    const isSmall = useIsSmallScreen();

    const rowActions = <RowActions editButton={CreateErrandFromInitialValuesButton} hasEdit hasDelete />;

    return (
        <List
            {...props}
            exporter={false}
            sort={defaultSort}
            filters={filters}
            bulkActionButtons={isSmall ? false : undefined}
        >
            {isSmall ? (
                <SmallDatagrid<ErrandDraft>
                    rowStyle={rowStyle}
                    expand={<ErrandDraftExpandPanel />}
                    rowActions={rowActions}
                >
                    {(record, { fieldName }) => {
                        return (
                            <>
                                <Typography component="div" variant="body2">
                                    {get(record, fieldName)}
                                </Typography>
                                <Typography component="div" variant="body2">
                                    {record?.errands?.length || 0}
                                </Typography>
                            </>
                        );
                    }}
                </SmallDatagrid>
            ) : (
                <Datagrid rowStyle={rowStyle} expand={<ErrandDraftExpandPanel />}>
                    <DateField source="createdAt" showTime />
                    <TextField source="name" sortable={false} />
                    <FunctionField<ErrandDraft>
                        label={translateResource('errands')}
                        render={(record) => (
                            <Typography component="span" variant="body2">
                                {record?.errands?.length || 0}
                            </Typography>
                        )}
                    />
                    {rowActions}
                </Datagrid>
            )}
        </List>
    );
};

export default ErrandDraftList;
