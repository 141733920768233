import { DateField, ListProps, ReferenceFieldProps, SimpleList, useRecordContext, useTranslate } from 'react-admin';
import { Alert } from '@material-ui/lab';
import { Box, Chip } from '@material-ui/core';
import { styled } from '@material-ui/core/styles';
import get from 'lodash/get';

import ListGuesser, { DatagridGuesser } from '@components/list/ListGuesser';
import RaReferenceField from '@components/field/ReferenceField';
import FunctionField from '@components/field/FunctionField';
import RowIconActions from '@components/list/RowIconActions';

import { secondsToHumanTime } from '@js/utility/numberFormatUtility';
import useResourceSchema from '@js/hooks/useResourceSchema';
import useFilters from './useFilters';
import useIsSmallScreen from '@js/hooks/useIsSmallScreen';

import { ProcessingTime } from '@js/interfaces/processingtime';
import { Errand } from '@js/interfaces/errand';
import { Reclamation } from '@js/interfaces/reclamation';
import { User } from '@js/interfaces/user';

const ProcessingTimeList = (props: ListProps) => {
    const filters = useFilters();
    const isSmall = useIsSmallScreen();
    const translate = useTranslate();

    return (
        <ListGuesser {...props} filters={filters}>
            {isSmall ? (
                <SimpleList<ProcessingTime>
                    linkType={false}
                    primaryText={(record) => <ProcessingTimeField source="time" record={record} />}
                    secondaryText={(record) => {
                        return (
                            <>
                                <DateField source="createdAt" record={record} showTime />
                                <div>{record.description}</div>
                            </>
                        );
                    }}
                    tertiaryText={(record) => {
                        return (
                            <Box>
                                <RaReferenceField source="createdBy" reference="users" record={record} linkType={false}>
                                    <FunctionField<User> render={(record) => record.fullName} />
                                </RaReferenceField>
                                <RowIconActions record={record} />
                            </Box>
                        );
                    }}
                />
            ) : (
                <DatagridGuesser rowActions={<RowIconActions />}>
                    <DateField source="createdAt" showTime />
                    <ProcessingTimeField source="time" />
                    <ReferenceField source="errand" sortable={false} />
                    <ReferenceField source="reclamation" sortable={false} />
                    <FunctionField<ProcessingTime>
                        source="autoCreationReason"
                        sortable={false}
                        render={(record) =>
                            record.autoCreationReason
                                ? translate(
                                      `resources.processing_times.auto_create_reason.${record.autoCreationReason}`,
                                  )
                                : null
                        }
                    />
                </DatagridGuesser>
            )}
        </ListGuesser>
    );
};

const ProcessingTimeField = (props: { source: string; record?: ProcessingTime }) => {
    const record = useRecordContext(props);

    return <span>{`${secondsToHumanTime(record?.time ?? 0)} h`}</span>;
};

const ReferenceField = (props: Omit<ReferenceFieldProps, 'children' | 'reference'>) => {
    const schema = useResourceSchema(props);
    const field = schema.fields?.find((field) => field.name === props.source);

    if (field?.reference !== null && typeof field?.reference === 'object' && field.maxCardinality === 1) {
        const getLabel = (record: Errand | Reclamation) => {
            const label = get(record, 'name') || get(record, 'label');

            if (label) {
                return `${label} (${record.composedIdentifier})`;
            }
            return record.composedIdentifier;
        };

        return (
            <RaReferenceField {...props} reference={field.reference.name}>
                <FunctionField<Errand | Reclamation> render={(record) => <StyledChip label={getLabel(record)} />} />
            </RaReferenceField>
        );
    }

    return <Alert severity="warning">Invalid field</Alert>;
};

const StyledChip = styled(Chip)({
    cursor: 'inherit',
    color: 'inherit',
});

export default ProcessingTimeList;
