import { BooleanField, ReferenceField, useRecordContext, useTranslate } from 'react-admin';

import FunctionField from '@components/field/FunctionField';

import InfoSummary from './InfoSummary';
import SelloStatusField from './SelloStatusField';

import useTranslateResourceField from '@js/hooks/useTranslateResourceField';
import { useFormatPrice } from '@js/hooks/useFormatPrice';
import { useUserPermissions } from '@js/context/UserPermissionsContext';

import { SelloSale } from '@js/interfaces/selloSale';
import { SelloPaymentMethod } from '@js/interfaces/SelloPaymentMethod';

type Props = {
    source?: string;
    record?: SelloSale;
    resource?: string;
    sortable?: boolean;
    addLabel?: boolean;
    label?: string;
};

const SelloSaleAdditionalInfoField = (props: Props) => {
    const record = useRecordContext(props);
    const getFieldLabel = useTranslateResourceField('sales');
    const formatPrice = useFormatPrice();
    const { isAdmin } = useUserPermissions();

    if (!record) return null;

    const items = [
        {
            label: getFieldLabel('totalVat'),
            value: formatPrice(record.totalVat),
        },
        {
            label: getFieldLabel('daysToSettlement', 'sello_payment_methods'),
            value: <SelloPaymentMethodField source="selloPaymentMethod" />,
        },
        {
            label: getFieldLabel('delivered'),
            value: <BooleanField source="delivered" />,
        },
    ];
    if (isAdmin) {
        items.push({
            label: getFieldLabel('selloStatus'),
            value: <SelloStatusField source="selloStatus" />,
        });
    }

    return <InfoSummary items={items} />;
};

SelloSaleAdditionalInfoField.defaultProps = {
    source: 'additionalInfo',
    sortable: false,
};

const SelloPaymentMethodField = ({ source, ...props }: { source: string; record?: SelloSale }) => {
    const translate = useTranslate();
    const record = useRecordContext(props);

    if (!record) return null;

    const soldAt = new Date(record.soldAt);

    return (
        <ReferenceField
            reference="sello_payment_methods"
            source={source}
            emptyText={translate('app.label.not_defined')}
            link={false}
        >
            <FunctionField<SelloPaymentMethod>
                render={(record) => {
                    let settlementLabel = '';

                    if (record.daysToSettlement) {
                        const daysPassedFromSoldDate = Math.floor((new Date().getTime() - soldAt.getTime()) / 86400000);

                        let daysLeftForSettlement = record.daysToSettlement - daysPassedFromSoldDate;
                        if (daysLeftForSettlement < 0) {
                            daysLeftForSettlement = 0;
                        }

                        settlementLabel = `${daysLeftForSettlement} ${translate('app.label.of')} ${
                            record.daysToSettlement
                        }`;
                    } else {
                        settlementLabel = translate('app.label.n/a');
                    }

                    return `${settlementLabel} (${record.name || record.selloId})`;
                }}
            />
        </ReferenceField>
    );
};

export default SelloSaleAdditionalInfoField;
