import { createContext, FC, ReactNode, useCallback, useContext, useMemo, useState } from 'react';
import { Feature, Settings } from '@js/interfaces/settings';
import { ADMIN_APP } from '@js/global';

// Mirror of src/Webhook/App.php
export enum App {
    Localhost = 'localhost',
    Riv = 'riv',
    Teleservice = 'teleservice',
    GiabS = 'giabs',
}

// @see \App\I18n\Country
export type Country = 'SE' | 'NO';

// @see \App\I18n\Locale
export type Locale = 'en' | 'sv' | 'no' | 'da';

// @see \App\I18n\Currency
export enum Currency {
    SEK = 'SEK',
    EUR = 'EUR',
    DKK = 'DKK',
    NOK = 'NOK',
}

// @see config/services.yaml "corerely.js_translation.domains"
export type TranslationDomain = 'app' | 'ra' | 'resources';

type AppConfigContextValue = ADMIN_APP & {
    isMobile: boolean;
};

interface AppConfigUpdateContextValue {
    updateSettings: (settings: Partial<Pick<Settings, 'logoFile' | 'loginPageBackgroundColor'>>) => void;
}

const AppConfigContext = createContext<AppConfigContextValue>(undefined!);
const AppConfigUpdateContext = createContext<AppConfigUpdateContextValue>(undefined!);

export const AppConfigContextProvider: FC<{ children: ReactNode }> = ({ children }) => {
    const [state, setState] = useState<AppConfigContextValue>(() => ({
        ...window.ADMIN_APP,
        isMobile: /Mobi|Android|iPhone|iPad/i.test(navigator.userAgent),
    }));

    const updateContextValue = useMemo<AppConfigUpdateContextValue>(
        () => ({
            updateSettings: (settings) => {
                setState((prevState) => ({
                    ...prevState,
                    settings: {
                        ...prevState.settings,
                        ...settings,
                    },
                }));
            },
        }),
        [],
    );

    return (
        <AppConfigContext.Provider value={state}>
            <AppConfigUpdateContext.Provider value={updateContextValue}>{children}</AppConfigUpdateContext.Provider>
        </AppConfigContext.Provider>
    );
};

export const useAppConfigContext = () => {
    return useContext(AppConfigContext);
};

export const useEntrypoint = () => {
    const { entrypoint } = useContext(AppConfigContext);

    return entrypoint;
};

export const useSettings = () => {
    const { settings } = useContext(AppConfigContext);

    return settings;
};

export const useIsFeatureDisabled = () => {
    const settings = useSettings();

    return useCallback(
        (feature: Feature) => {
            return settings.features[`disable${feature}`];
        },
        [settings],
    );
};

export const useIsResourceDisabled = () => {
    const isFeatureDisabled = useIsFeatureDisabled();

    return useCallback(
        (resource: string) => {
            const resourceToFeatureMap: { [key: string]: Feature } = {
                products: 'SplitProducts',
                errand_drafts: 'ReturnForm',
            };

            return resource in resourceToFeatureMap && isFeatureDisabled(resourceToFeatureMap[resource]);
        },
        [isFeatureDisabled],
    );
};

export const useUpdateSettings = () => {
    const { updateSettings } = useContext(AppConfigUpdateContext);

    return updateSettings;
};

export const useLanguageChoices = () => {
    const {
        i18n: { locales },
    } = useAppConfigContext();

    return locales.map((locale) => ({
        id: locale,
        name: `app.translation.language.${locale}`,
    }));
};

export const useCurrencyChoices = () => {
    const { currencies } = useAppConfigContext();

    return currencies.map((currency) => ({
        id: currency,
        name: currency,
    }));
};

export const useCountryChoices = () => {
    const { countries } = useAppConfigContext();

    return countries.map((country) => ({
        id: country,
        name: `app.translation.country.${country}`,
    }));
};

export const APP_CHOICES = Object.values(App)
    .filter((app) => app !== App.Localhost)
    .map((app) => ({ id: app, name: `app.app_name.${app}` }));

export const useIsMobile = () => {
    const { isMobile } = useAppConfigContext();
    return isMobile;
};
